import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { Breadcrumbs, Button, TextField } from "@mui/material/";
import { Box } from "@mui/system";
import { DataGrid, GridColDef, GridRowsProp } from "@mui/x-data-grid";
import React from "react";
import { useCookies } from "react-cookie";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useSelector } from "store/configureStore";

import MatchSiteGridActionCell from "../../component/datagrid/MatchSiteGridAction";
import { setState as setCommonState } from "../../store/Common/information";
import store from "../../store/configureStore";
import { MatchInfo } from "../../store/Site/definition";
import {
  deletematchdata,
  deleteTournamentData,
  fetchTournamentList,
  getmatchdatas,
  registmatchdata,
} from "../../store/Site/operations";

interface Props {}

const MatchRegist: React.FC<Props> = () => {
  const dispatch = useDispatch();
  const [cookies, setCookie, removeCookie] = useCookies(["bs-token"]);
  const data: boolean = useSelector((state) => state.site.matchData);
  const { tid } = useParams();

  const navigate = useNavigate();

  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", width: 50 },
    {
      field: "player1",
      headerName: "プレイヤー1",
      width: 200,
      renderCell: (params) => renderPlayer1(params.row.match_info),
    },
    {
      field: "player2",
      headerName: "プレイヤー2",
      width: 200,
      renderCell: (params) => renderPlayer2(params.row.match_info),
    },
    {
      field: "player1_score",
      headerName: "P1Win",
      width: 80,
      renderCell: (params) => renderPlayer1Win(params.row.match_info),
    },
    {
      field: "player2_score",
      headerName: "P2Win",
      width: 80,
      renderCell: (params) => renderPlayer2Win(params.row.match_info),
    },
    { field: "createdate", headerName: "登録日", width: 200 },
    {
      field: "actions",
      headerName: "操作",
      width: 200,
      renderCell: (params) => (
        <MatchSiteGridActionCell
          onEdit={() => handleEdit(params.row.id)}
          onClick={() => handleDelete(params.row.id)}
        />
      ),
    },
  ];

  const renderPlayer1 = (match_info: string) => {
    const match_data: MatchInfo = JSON.parse(match_info);
    return match_data?.player1_name;
  };
  const renderPlayer2 = (match_info: string) => {
    const match_data: MatchInfo = JSON.parse(match_info);
    return match_data?.player2_name;
  };
  const renderPlayer1Win = (match_info: string) => {
    const match_data: MatchInfo = JSON.parse(match_info);
    return match_data?.player1_score;
  };
  const renderPlayer2Win = (match_info: string) => {
    const match_data: MatchInfo = JSON.parse(match_info);
    return match_data?.player2_score;
  };

  const [open, setOpen] = React.useState(false);
  const [addmatchnum, setAddMatchNum] = React.useState(0);
  const handleCreate = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleAddMatchdata = async () => {
    const result = await dispatch<ReturnType<typeof registmatchdata>>(
      registmatchdata({
        payload: {
          tournament_id: tid,
          match_num: addmatchnum,
        },
        access_token: cookies["bs-access-token"],
      })
    );
    fetchData();
  };

  const handleDelete = async (id: number) => {
    const result = await dispatch<ReturnType<typeof deletematchdata>>(
      deletematchdata({
        payload: {
          id: id,
        },
        access_token: cookies["bs-access-token"],
      })
    );
    fetchData();
  };

  const handleEdit = (id: number) => {
    navigate(`/site/regist/match/${tid}/edit/${id}`);
  };

  const fetchData = async () => {
    const result = await dispatch<ReturnType<typeof getmatchdatas>>(
      getmatchdatas({
        payload: {
          tournament_id: tid,
        },
        access_token: cookies["bs-access-token"],
      })
    );
  };
  React.useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      <Breadcrumbs aria-label="breadcrumb">
        <Link to="/site/tournament/">トーナメント一覧</Link>
        <Link>試合一覧</Link>
      </Breadcrumbs>
      <h2>
        試合一覧
        <Button onClick={handleCreate} sx={{ ml: 2 }} variant="contained">
          新規登録
        </Button>
      </h2>
      <Box sx={{ backgroundColor: "white" }}>
        <DataGrid rowHeight={100} rows={data} columns={columns} />
      </Box>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>試合登録</DialogTitle>
        <DialogContent>
          <DialogContentText>
            追加したい試合の数を入力してください。
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="match_num"
            label="試合数"
            type="number"
            onChange={(e) => setAddMatchNum(Number(e.target.value))}
            value={addmatchnum}
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>キャンセル</Button>
          <Button onClick={handleAddMatchdata}>登録</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default MatchRegist;
