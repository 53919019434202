import { Button, TextField, Typography } from "@mui/material";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { Box } from "@mui/system";
import React, { useRef, useState } from "react";
import { useAsyncCallback } from "react-async-hook";
import { useCookies } from "react-cookie";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "store/configureStore";
import moment from "moment";
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import noimage from "../../../assets/noimage.png";
import CircularIntegration from "../../component/parts/CircularIntegration";
import { IMAGE_DOMAIN } from "../../const/constatns";
import { setState as setCommonState } from "../../store/Common/information";
import store from "../../store/configureStore";
import { EventData, TournamentOneData } from "../../store/Site/definition";
import { fetchTournamentData } from "../../store/Site/operations";
import {
  editTournamentData,
  fetchAllEventList,
} from "../../store/Site/operations";

interface Props {}

const TournamentEdit: React.FC<Props> = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [cookies, setCookie, removeCookie] = useCookies(["bs-token"]);
  const [roleName, seteRoleName] = useState("新規登録");
  const data: TournamentOneData = useSelector(
    (state) => state.site.tournamentOneData
  );
  const eventData: Array<EventData> = useSelector(
    (state) => state.site.eventData
  );
  const { id } = useParams();

  const [tournamentId, setTournamentId] = useState(null);
  const [eventId, setEventId] = useState("0");
  const [uploadFileData, setUploadFileData] = useState(null);
  const [previewData, setPreviewData] = useState(null);
  const [tournamentName, setTournamentName] = useState("");  
  const [text, setText] = useState("");
  const [summary, setSummary] = useState("");
  const [linkinfo, setLinkinfo] = useState("");
  const [movie_embed, setMovieEmbed] = useState("");
  const [eventdate,setEventdate] = useState<moment | null>(
    moment(),
  );
  const inputRef = useRef(null);
  const [success, setSuccess] = useState(false);

  const uploadFile = async (file) => {
    if (!file) return;
    setUploadFileData(file);
    setPreviewData(window.URL.createObjectURL(file));
    setSuccess(true);
  };

  const onFileInputChange = async (event) => {
    const file = event.target.files[0];
    await uploadFile(file);
  };

  const clickFileUploadButton = () => {
    setSuccess(false);
    inputRef.current.click();
  };

  const asyncEvent = useAsyncCallback(onFileInputChange);
  const handleChange = (event: SelectChangeEvent) => {    
    setEventId(event.target.value);
  };
  const handleEventDateChange =  (newValue: moment | null) => {
    setEventdate(newValue);
  };

  const handleEdit = async () => {
    const result = await dispatch<ReturnType<typeof editTournamentData>>(
      editTournamentData({
        payload: {
          id: id,
          event_id: eventId,
          name: tournamentName,
          text: text,
          summary: summary,
          linkinfo: linkinfo,
          movie_embed: movie_embed,
          eventdate: eventdate.format("YYYY-MM-DD"),
          file: uploadFileData,
        },
        access_token: cookies["bs-access-token"],
      })
    );
    if (result.payload["id"] != undefined) {
      store.dispatch(
        setCommonState({
          alertDialogOpen: true,
          alertDialogMessage: `トーナメントデータの${roleName}を行いました`,
          alertDialogType: "success",
        })
      );
      store.dispatch(setCommonState({}));

      setTournamentId(result.payload["id"]);
      navigate(`/site/tournament/edit/${result.payload["id"]}`, {
        replace: true,
      });
    } else {
      store.dispatch(
        setCommonState({
          alertDialogOpen: true,
          alertDialogMessage: `トーナメントデータの${roleName}に失敗しました。`,
          alertDialogType: "error",
        })
      );
    }
  };
  const fetchData = async () => {
    const result = await dispatch<ReturnType<typeof fetchTournamentData>>(
      fetchTournamentData({
        payload: {
          id: tournamentId,
        },
        access_token: cookies["bs-access-token"],
      })
    );
  };
  const fetchEventData = async () => {
    const result = await dispatch<ReturnType<typeof fetchAllEventList>>(
      fetchAllEventList({
        payload: {},
        access_token: cookies["bs-access-token"],
      })
    );
  };

  React.useEffect(() => {
    if (data != null) {
      setEventId(data.tournament.event_id);
      setTournamentName(data.tournament.name);
      setText(data.tournament.text);
      setSummary(data.tournament.summary);
      setLinkinfo(data.tournament.linkinfo);
      setMovieEmbed(data.tournament.movie_embed);
      if (data.tournament.imgpath != null) {
        setPreviewData(IMAGE_DOMAIN + data.tournament.imgpath);
      }
    }
  }, [data]);

  React.useEffect(() => {
    if (tournamentId != undefined) {
      fetchData();
      seteRoleName("編集");
    } else {
      seteRoleName("新規登録");
    }
  }, [tournamentId]);

  React.useEffect(() => {
    if (id != undefined) setTournamentId(id);

    setTournamentName("");
    setEventId("0");
    setText("");
    setSummary("");
    setLinkinfo("");
    setMovieEmbed("");
    setPreviewData("");

    fetchEventData();
  }, []);

  return (
    <div>
      <Breadcrumbs aria-label="breadcrumb">
        <Link to="/site/tournament">トーナメント一覧</Link>
        <Link>{roleName}</Link>
      </Breadcrumbs>
      <h2>トーナメントデータ{roleName}</h2>
      {tournamentId != undefined ? (
        <Typography variant="subtitle1">ID: {tournamentId}</Typography>
      ) : (
        ""
      )}
      <Box sx={{ mt: 1 }}>
        <TextField
          id="tournament_data_name"
          sx={{ width: 400 }}
          value={tournamentName}
          onChange={(event) => setTournamentName(event.target.value)}
          label="トーナメント名"
          variant="outlined"
        />
      </Box>
      <Box sx={{ mt: 1 }}>
        <Select
          labelId="select-gamelist-label"
          id="select-gamelist-label"
          label="イベント選択"
          value={eventId}
          onChange={handleChange}
        >
          <MenuItem key="0" value="0">
            イベントを選択してください
          </MenuItem>
          {eventData.map((event) => (
            <MenuItem key={event.id} value={event.id}>
              {event.eventname}
            </MenuItem>
          ))}
        </Select>
      </Box>
      <Box sx={{ mt: 1 }}>
        <TextField
          id="tournament_data_text"
          sx={{ width: 600 }}
          value={text}
          onChange={(event) => setText(event.target.value)}
          multiline
          rows={3}
          label="概要"
          variant="outlined"
        />
      </Box>
      <Box sx={{ mt: 1 }}>
        <TextField
          id="tournament_data_summary"
          sx={{ width: 600 }}
          value={summary}
          onChange={(event) => setSummary(event.target.value)}
          multiline
          rows={6}
          label="詳細説明"
          variant="outlined"
        />
      </Box>
      <Box sx={{ mt: 1 }}>
        <TextField
          id="tournament_data_linkinfo"
          sx={{ width: 600 }}
          value={linkinfo}
          onChange={(event) => setLinkinfo(event.target.value)}
          label="リンク情報"
          variant="outlined"
        />
      </Box>
      <Box sx={{ mt: 1 }}>
        <TextField
          id="tournament_data_movie_embed"
          sx={{ width: 600 }}
          value={movie_embed}
          onChange={(event) => setMovieEmbed(event.target.value)}
          multiline
          rows={3}
          label="動画組み込み"
          variant="outlined"
        />
      </Box>
      <Box sx={{ mt: 1 }}>
        <DesktopDatePicker
          label="イベント日"
          inputFormat="YYYY/MM/DD"
          value={eventdate}
          onChange={handleEventDateChange}
          renderInput={(params) => <TextField {...params} />}
        />
      </Box>
      <Box sx={{ mt: 1 }}>
        <img height={150} src={previewData == null ? noimage : previewData} />
        <CircularIntegration
          onClick={clickFileUploadButton}
          asyncEvent={asyncEvent}
          success={success}
          component="label"
          text={asyncEvent.loading ? "..." : "トーナメントサムネイル画像を選択"}
        />
        <input
          hidden
          ref={inputRef}
          type="file"
          onChange={asyncEvent.execute}
        />
      </Box>
      <Box sx={{ mt: 1 }}></Box>
      <Box sx={{ mt: 4 }}>
        <Button
          onClick={handleEdit}
          style={{ backgroundColor: "#FFA500" }}
          variant="contained"
        >
          {roleName}する
        </Button>
      </Box>
    </div>
  );
};

export default TournamentEdit;
