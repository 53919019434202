import { Button, TextField, Typography } from "@mui/material";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { Box } from "@mui/system";
import React, { useRef, useState } from "react";
import { useAsyncCallback } from "react-async-hook";
import { useCookies } from "react-cookie";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "store/configureStore";

import noimage from "../../../assets/noimage.png";
import CircularIntegration from "../../component/parts/CircularIntegration";
import { IMAGE_DOMAIN } from "../../const/constatns";
import { setState as setCommonState } from "../../store/Common/information";
import store from "../../store/configureStore";
import { EventData, PlayerDataWithDetail, PlayerDetailJson, TournamentOneData } from "../../store/Site/definition";
import { editPlayerData, fetchTournamentData, getPlayerData } from "../../store/Site/operations";
import {
  editTournamentData,
  fetchAllEventList,
} from "../../store/Site/operations";

interface Props {}

const PlayerEdit: React.FC<Props> = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [cookies, setCookie, removeCookie] = useCookies(["bs-token"]);
  const [roleName, seteRoleName] = useState("新規登録");
  const playerDetailData: PlayerDataWithDetail = useSelector(
    (state) => state.site.playerDetailData
  );
  const { id } = useParams();

  const [playerId, setPlayerId] = useState(null);
  const [customId, setCustomId] = useState("");
  const [uploadFileData, setUploadFileData] = useState(null);
  const [previewData, setPreviewData] = useState(null);
  const [playerName, setPlayerName] = useState("");
  const [detail, setDetail] = useState("");
  const [searchNames, setSearchNames] = useState("");

  const inputRef = useRef(null);
  const [success, setSuccess] = useState(false);

  const uploadFile = async (file) => {
    if (!file) return;
    setUploadFileData(file);
    setPreviewData(window.URL.createObjectURL(file));
    setSuccess(true);
  };

  const onFileInputChange = async (event) => {
    const file = event.target.files[0];
    await uploadFile(file);
  };

  const clickFileUploadButton = () => {
    setSuccess(false);
    inputRef.current.click();
  };

  const asyncEvent = useAsyncCallback(onFileInputChange);
  
  const handleEdit = async () => {
    const result = await dispatch<ReturnType<typeof editPlayerData>>(
      editPlayerData({
        payload: {
          id: id,
          custom_id: customId,
          detail: detail,
          searchnames: searchNames,
          file: uploadFileData,
        },
        access_token: cookies["bs-access-token"],
      })
    );
    if (result.payload["id"] != undefined) {
      store.dispatch(
        setCommonState({
          alertDialogOpen: true,
          alertDialogMessage: `プレイヤーデータの${roleName}を行いました`,
          alertDialogType: "success",
        })
      );
      store.dispatch(setCommonState({}));
    } else {
      store.dispatch(
        setCommonState({
          alertDialogOpen: true,
          alertDialogMessage: `トーナメントデータの${roleName}に失敗しました。`,
          alertDialogType: "error",
        })
      );
    }
  };

  const fetchPlayerData = async (id:number) => {
    var search_id = id;
    if(playerId!==undefined)
      search_id = playerId;
    if(search_id!=undefined){
      const result = await dispatch<ReturnType<typeof getPlayerData>>(
        getPlayerData({
          payload: {
            id: search_id,
          },
          access_token: cookies["bs-access-token"],
        })
      );
    }
  };

  React.useEffect(() => {
    if (playerDetailData != null) {
      setPlayerName(playerDetailData.name);
      if(playerDetailData.detail!=undefined && playerDetailData.detail.detail!=undefined){
        const detail_info: PlayerDetailJson = JSON.parse(playerDetailData.detail.detail);      
        setDetail(detail_info.description);
        setSearchNames(detail_info.searchnames);
      }
      setCustomId(playerDetailData.custom_id);
      if (playerDetailData.detail?.imgpath != null) {
        setPreviewData(IMAGE_DOMAIN + playerDetailData.detail?.imgpath);
      }
    }
  }, [playerDetailData]);

  React.useEffect(() => {
    if (id != undefined) {
      fetchPlayerData(id);
      seteRoleName("編集");
    } else {
      seteRoleName("新規登録");
    }
  }, [playerId]);

  React.useEffect(() => {
    if (id != undefined) setPlayerId(id);

    setPlayerName("");
    setDetail("");
    setSearchNames("");
    setPreviewData("");

    //fetchPlayerData();
  }, []);

  return (
    <div>
      <Breadcrumbs aria-label="breadcrumb">
        <Link to="/site/player">プレイヤー情報一覧</Link>
        <Link>{roleName}</Link>
      </Breadcrumbs>
      <h2>プレイヤー情報{roleName}</h2>
      {customId != undefined ? (
        <Typography variant="subtitle1">CUSTOM_ID: {customId}</Typography>
      ) : (
        ""
      )}
      <Box sx={{ mt: 1 }}>
        <img height={150} src={previewData == null ? noimage : previewData} />
        <CircularIntegration
          onClick={clickFileUploadButton}
          asyncEvent={asyncEvent}
          success={success}
          component="label"
          text={asyncEvent.loading ? "..." : "プレイヤー画像を選択"}
        />
        <input
          hidden
          ref={inputRef}
          type="file"
          onChange={asyncEvent.execute}
        />
      </Box>
      <Box sx={{ mt: 1 }}>
        <TextField
          id="player_name"
          sx={{ width: 400 }}
          value={playerName}
          onChange={(event) => setPlayerName(event.target.value)}
          label="プレイヤー名"
          variant="outlined"
        />
      </Box>
      <Box sx={{ mt: 1 }}>
        <TextField
          id="detail"
          sx={{ width: 600 }}
          value={detail}
          onChange={(event) => setDetail(event.target.value)}
          multiline
          rows={3}
          label="説明"
          variant="outlined"
        />
      </Box>
      <Box sx={{ mt: 1 }}>
        <TextField
          id="searchNames"
          sx={{ width: 600 }}
          value={searchNames}
          onChange={(event) => setSearchNames(event.target.value)}
          multiline
          rows={6}
          label="タグ（名称等も含む）"
          variant="outlined"
        />
      </Box>
      <Box sx={{ mt: 4 }}>
        <Button
          onClick={handleEdit}
          style={{ backgroundColor: "#FFA500" }}
          variant="contained"
        >
          {roleName}する
        </Button>
      </Box>
    </div>
  );
};

export default PlayerEdit;
