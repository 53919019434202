import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Tooltip from "@mui/material/Tooltip";
import React from "react";

const GameMasterDataAdminActionCell = ({ onEdit, onClick }) => (
  <div>
    <Tooltip title="編集">
      <EditIcon sx={{ cursor: "pointer" }} onClick={onEdit} />
    </Tooltip>
    <Tooltip title="削除">
      <DeleteIcon sx={{ cursor: "pointer" }} onClick={onClick} />
    </Tooltip>
  </div>
);

export default GameMasterDataAdminActionCell;
