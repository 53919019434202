import BookIcon from '@mui/icons-material/Book';
import DeleteIcon from "@mui/icons-material/Delete";
import Tooltip from "@mui/material/Tooltip";
import React from "react";
import PersonIcon from '@mui/icons-material/Person';
const ActionCell = ({
  onPlayerRegist,
  onAnalytics,
  onDelete,
}) => (
  <div>
    <Tooltip title="プレイヤー詳細">
      <PersonIcon sx={{ cursor: "pointer" }} onClick={onPlayerRegist} />
    </Tooltip>
    <Tooltip title="ブログ記事">
      <BookIcon sx={{ cursor: "pointer" }} onClick={onAnalytics} />
    </Tooltip>    
    <Tooltip title="削除">
      <DeleteIcon sx={{ cursor: "pointer" }} onClick={onDelete} />
    </Tooltip>    
  </div>
);

export default ActionCell;
