import { Link, Typography } from "@mui/material";
import { Theme } from "@mui/material/styles";
import React from "react";
import { useCookies } from "react-cookie";
import { useSelector } from "store/configureStore";
import styled, { css } from "styled-components";

interface Props {
  children: React.ReactNode;
}

const Login: React.FC<Props> = (props: Props) => {
  const loginData = useSelector((state) => state.login.data);
  const [cookies, setCookie, removeCookie] = useCookies(["bs-token"]);
  const [accessToken, setAccessToken] = React.useState<string>("");

  React.useEffect(() => {
    if (loginData != undefined) {
      setAccessToken(loginData.access_token);
    }
  }, [loginData]);

  React.useEffect(() => {
    if (cookies["bs-access-token"] != undefined) {
      setAccessToken(cookies["bs-access-token"]);
    }
  }, []);

  return (
    <RootDiv>
      <MainContent>
        {props.children}
        <Copyright />
        <meta name="api-token" content={accessToken} />
      </MainContent>
    </RootDiv>
  );
};

const RootDiv = styled.div`
  display: flex;
  flex-direction: column;
`;

const MainContent = styled.main`
  ${(props: { theme: Theme }) => css`
    width: auto;
  `}
`;
function Copyright(props: any) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://battle-score.com/">
        BattleScore
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

export default Login;
