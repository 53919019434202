import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import merge from "lodash/merge";

import {
  ArticleData,
  PlayerAnalyticsData,
  PlayerRankingInfo,
  TournamentPlayerRanking,
  TournamentRankingData,
} from "./definition";
import {
  editarticle,
  fetchAnalyticsTournament,
  getarticle,
  geteventcharacterranking,
  geteventplayerranking,
  geteventranking,
  getgamecharacterplayingranking,
  getgamecharacterplayranking,
  getgameranking,
  getplayerpersonalanalytics,
  gettournamentcharacterranking,
  gettournamentmatchlist,
  gettournamentplayerranking,
  gettournamentranking,
  makebattlescorearticle,
} from "./operations";

type State = {
  rankingData: any;
  playerAnalyticsData: PlayerAnalyticsData | [];
  tournamentRankingData: TournamentRankingData | [];
  tournamentPlayerRankingData: PlayerRankingInfo[] | [];
  articleData: ArticleData | null;
  status: string;
  error: string;
};

const initialState: State = {
  rankingData: [],
  playerAnalyticsData: [],
  tournamentRankingData: [],
  tournamentPlayerRankingData: [],
  articleData: null,
  status: "",
  error: "",
};

const slice = createSlice({
  name: "site",
  initialState,
  reducers: {
    initializeState: (state) => {
      return Object.assign({}, initialState);
    },
    setState: (state, action: PayloadAction<Partial<State>>) => {
      Object.keys(action.payload).map((key) => {
        merge(state, { [key]: action.payload[key] });
      });
    },
    resetRankingData: (state) => {
      state.rankingData = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAnalyticsTournament.fulfilled, (state, action) => {
      state.status = "succeeded";
    });
    builder.addCase(getarticle.fulfilled, (state, action) => {
      state.articleData = action.payload;
      state.status = "succeeded";
    });
    builder.addCase(editarticle.fulfilled, (state, action) => {
      state.status = "succeeded";
    });
    builder.addCase(makebattlescorearticle.fulfilled, (state, action) => {
      state.status = "succeeded";
    });
    builder.addCase(gettournamentranking.fulfilled, (state, action) => {
      state.tournamentRankingData = action.payload;
      state.rankingData = action.payload;
      state.status = "succeeded";
    });
    builder.addCase(gettournamentplayerranking.fulfilled, (state, action) => {
      const prData: TournamentPlayerRanking = action.payload;
      const listData: PlayerRankingInfo[] = [];
      //prDataのkeyに設定されている配列を取得
      Object.keys(prData).map((key) => {
        prData[key].map((data) => {
          listData.push(data);
        });
      });
      state.rankingData = listData;
      state.tournamentPlayerRankingData = listData;
      state.status = "succeeded";
    });
    builder.addCase(
      gettournamentcharacterranking.fulfilled,
      (state, action) => {
        state.rankingData = action.payload;
        state.status = "succeeded";
      }
    );
    builder.addCase(gettournamentmatchlist.fulfilled, (state, action) => {
      state.rankingData = action.payload;
      state.status = "succeeded";
    });
    builder.addCase(geteventranking.fulfilled, (state, action) => {
      state.rankingData = action.payload;
      state.status = "succeeded";
    });
    builder.addCase(geteventplayerranking.fulfilled, (state, action) => {
      const prData: TournamentPlayerRanking = action.payload;
      const listData: PlayerRankingInfo[] = [];
      //prDataのkeyに設定されている配列を取得
      Object.keys(prData).map((key) => {
        prData[key].map((data) => {
          listData.push(data);
        });
      });
      state.rankingData = listData;
      state.status = "succeeded";
    });
    builder.addCase(geteventcharacterranking.fulfilled, (state, action) => {
      state.rankingData = action.payload;
      state.status = "succeeded";
    });
    builder.addCase(getgameranking.fulfilled, (state, action) => {
      state.rankingData = action.payload;
      state.status = "succeeded";
    });
    builder.addCase(getgamecharacterplayranking.fulfilled, (state, action) => {
      const prData: TournamentPlayerRanking = action.payload;
      const listData: PlayerRankingInfo[] = [];
      //prDataのkeyに設定されている配列を取得
      Object.keys(prData).map((key) => {
        prData[key].map((data) => {
          listData.push(data);
        });
      });
      state.rankingData = listData;
      state.status = "succeeded";
    });
    builder.addCase(
      getgamecharacterplayingranking.fulfilled,
      (state, action) => {
        state.rankingData = action.payload;
        state.status = "succeeded";
      }
    );
    builder.addCase(
      getplayerpersonalanalytics.fulfilled,
      (state, action) => {
        state.playerAnalyticsData = action.payload;
        state.status = "succeeded";
      }
    );
  },
});

export default slice.reducer;
export const { initializeState, setState, resetRankingData } = slice.actions;
