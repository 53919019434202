import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import merge from "lodash/merge";

import {
  fetchLogin,
  fetchOrganizer,
  fetchRegist,
  fetchUser,
} from "./operations";

interface Organizer {
  id: number;
  name: string;
  imgpath: string;
}

type State = {
  data: object | null;
  status: string;
  error: string;
  isLoggedIn: boolean;
  organizerData: Array<Organizer>;
};

const initialState: State = {
  data: null,
  status: "",
  error: "",
  isLoggedIn: false,
  organizerData: [],
};

const slice = createSlice({
  name: "login",
  initialState,
  reducers: {
    initializeState: (state) => {
      return Object.assign({}, initialState);
    },
    setState: (state, action: PayloadAction<Partial<State>>) => {
      Object.keys(action.payload).map((key) => {
        merge(state, { [key]: action.payload[key] });
      });
    },
  },
  extraReducers: (builder) => {
    builder.addCase("information/setState", (state, action) => {
      state.errorMessage = "";
    });
    builder.addCase(fetchRegist.pending, (state) => {
      state.status = "pending";
    });
    builder.addCase(fetchRegist.fulfilled, (state, action) => {
      state.data = action.payload;
      state.status = "succeeded";
    });
    builder.addCase(fetchRegist.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    });
    builder.addCase(fetchLogin.pending, (state) => {
      state.status = "pending";
    });
    builder.addCase(fetchLogin.fulfilled, (state, action) => {
      state.data = action.payload;
      state.status = "succeeded";
    });
    builder.addCase(fetchLogin.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    });
    builder.addCase(fetchUser.fulfilled, (state, action) => {
      state.status = "succeeded";
    });
    builder.addCase(fetchOrganizer.fulfilled, (state, action) => {
      state.organizerData = action.payload;
      state.status = "succeeded";
    });
  },
});

export default slice.reducer;

export const { initializeState, setState } = slice.actions;
