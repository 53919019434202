import { configureStore } from "@reduxjs/toolkit";
import {
  TypedUseSelectorHook,
  useSelector as useReduxSelector,
} from "react-redux";
import { combineReducers } from "redux";

import AdminReducer from "./Admin/information";
import AnalyticsReducer from "./Analytics/information";
import informationReducer from "./Common/information";
import loginReducer from "./Login/information";
import SiteReducer from "./Site/information";

const reducer = combineReducers({
  information: informationReducer,
  login: loginReducer,
  site: SiteReducer,
  admin: AdminReducer,
  analytics: AnalyticsReducer,
});

const store = configureStore({
  reducer: reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type RootState = ReturnType<typeof store.getState>;
export const useSelector: TypedUseSelectorHook<RootState> = useReduxSelector;

export default store;
