import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import {
  Autocomplete,
  Breadcrumbs,
  Button,
  Divider,
  TextField,
  Typography,
} from "@mui/material/";
import Grid from "@mui/material/Unstable_Grid2";
import { Box } from "@mui/system";
import React from "react";
import { useCookies } from "react-cookie";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import YouTube from "react-youtube";
import { useSelector } from "store/configureStore";

import { GameCharacter, GameMasterData } from "../../store/Admin/definition";
import {
  getgamecharacters,
  getgamedatamaster,
} from "../../store/Admin/operations";
import { setState as setCommonState } from "../../store/Common/information";
import store from "../../store/configureStore";
import { MatchData, MatchInfo, PlayerData } from "../../store/Site/definition";
import {
  deleteTournamentData,
  fetchPlayerInfoDatas,
  fetchTournamentList,
  getmatchdata,
  updatematchdata,
} from "../../store/Site/operations";

interface Props { }

type VideoProps = {
  videoId: string;
};
const YoutubePlayer: React.FC<VideoProps> = ({ videoId }) => {
  const opts = {
    height: "220",
    width: "400",
    playerVars: {
      autoplay: 0,
    },
  };

  return <YouTube videoId={videoId} opts={opts} />;
};
const getVideoIdFromUrl = (url: string) => {
  const regex =
    /(?:youtube\.com\/(?:[^/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?/ ]{11})/;
  const match = url.match(regex);
  return match ? match[1] : null;
};

interface PlayerOptions {
  label: string;
  id: number;
}

const MatchEdit: React.FC<Props> = () => {
  const dispatch = useDispatch();
  const [cookies, setCookie, removeCookie] = useCookies(["bs-token"]);
  const matchData: MatchData = useSelector((state) => state.site.matchOneData);
  const character_data: GameCharacter = useSelector(
    (state) => state.admin.characterData
  );
  const player_Data: PlayerData = useSelector((state) => state.site.playerData);
  const game_master_data: GameMasterData[] = useSelector(
    (state) => state.admin.gameMasterData
  );

  const { tid, id } = useParams();
  const navigate = useNavigate();

  const [open, setOpen] = React.useState(false);
  const [movieUrl, setMovieUrl] = React.useState<string | null>("");

  const [playerDatas, setPlayerDatas] = React.useState<PlayerOptions[]>([]);
  const [characterDatas, setCharacterDatas] = React.useState<PlayerOptions[]>(
    []
  );

  const [player1Value, setPlayer1Value] = React.useState<number | null>(0);
  const [player2Value, setPlayer2Value] = React.useState<number | null>(0);
  const [player1NameValue, setPlayer1NameValue] = React.useState<string | null>(0);
  const [player2NameValue, setPlayer2NameValue] = React.useState<string | null>(0);
  const [character1Value, setCharacter1Value] = React.useState<number | null>(0);
  const [character2Value, setCharacter2Value] = React.useState<number | null>(0);
  const [character1Num, setCharacter1Num] = React.useState<number | null>(0);
  const [character2Num, setCharacter2Num] = React.useState<number | null>(0);

  const [character1Value2, setCharacter1Value2] = React.useState<number | null>(0);
  const [character2Value2, setCharacter2Value2] = React.useState<number | null>(0);
  const [character1Num2, setCharacter1Num2] = React.useState<number | null>(0);
  const [character2Num2, setCharacter2Num2] = React.useState<number | null>(0);

  const [character1Value3, setCharacter1Value3] = React.useState<number | null>(0);
  const [character2Value3, setCharacter2Value3] = React.useState<number | null>(0);
  const [character1Num3, setCharacter1Num3] = React.useState<number | null>(0);
  const [character2Num3, setCharacter2Num3] = React.useState<number | null>(0);

  const [player1WinNum, setPlayer1WinNum] = React.useState<number | null>(0);
  const [player2WinNum, setPlayer2WinNum] = React.useState<number | null>(0);

  function isOptionEqualToValue(option: any, value: any) {
    return option.id === value.id;
  }

  const handleSettingMovieUrl = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleRegistURL = async () => {
    const result = await dispatch<ReturnType<typeof updatematchdata>>(
      updatematchdata({
        payload: {
          id: id,
          movie_url: movieUrl,
        },
        access_token: cookies["bs-access-token"],
      })
    );
    if (result.error == undefined) {
      store.dispatch(
        setCommonState({
          alertDialogOpen: true,
          alertDialogMessage: "動画URLを更新しました。",
          alertDialogType: "success",
        })
      );
    } else {
      store.dispatch(
        setCommonState({
          alertDialogOpen: true,
          alertDialogMessage: "動画URLの更新に失敗しました。",
          alertDialogType: "error",
        })
      );
      setMovieUrl("");
    }
    setOpen(false);
  };
  const handleSaveData = async () => {
    const result = await dispatch<ReturnType<typeof updatematchdata>>(
      updatematchdata({
        payload: {
          id: id,
          player1_id: player1Value,
          player2_id: player2Value,
          player1_name: player1NameValue,
          player2_name: player2NameValue,
          player1_character_id: character1Value,
          player2_character_id: character2Value,
          player1_character_num: parseInt(character1Num),
          player2_character_num: parseInt(character2Num),
          player1_character_id2: character1Value2,
          player2_character_id2: character2Value2,
          player1_character_num2: parseInt(character1Num2),
          player2_character_num2: parseInt(character2Num2),
          player1_character_id3: character1Value3,
          player2_character_id3: character2Value3,
          player1_character_num3: parseInt(character1Num3),
          player2_character_num3: parseInt(character2Num3),
          player1_score: parseInt(player1WinNum),
          player1_detail: player1Detail,
          player2_score: parseInt(player2WinNum),
          player2_detail: player2Detail,
        },
        access_token: cookies["bs-access-token"],
      })
    );
    if (result.error == undefined) {
      store.dispatch(
        setCommonState({
          alertDialogOpen: true,
          alertDialogMessage: "試合情報を更新しました。",
          alertDialogType: "success",
        })
      );
    } else {
      store.dispatch(
        setCommonState({
          alertDialogOpen: true,
          alertDialogMessage: "試合情報の更新に失敗しました。",
          alertDialogType: "error",
        })
      );
    }
  };

  const fetchPlayerData = async () => {
    const result = await dispatch<ReturnType<typeof fetchPlayerInfoDatas>>(
      fetchPlayerInfoDatas({
        payload: {
          tournament_id: tid,
        },
        access_token: cookies["bs-access-token"],
      })
    );
    const set_data: Array<PlayerOptions> = [];
    if (result.error == undefined) {
      for (let i = 0; i < result.payload.length; i++) {
        set_data.push({
          label: result.payload[i].name,
          id: result.payload[i].id,
        });
      }
      setPlayerDatas(set_data);
    }
  };
  const fetchGameCharacterData = async (gameId: number) => {
    const result = await dispatch<ReturnType<typeof getgamecharacters>>(
      getgamecharacters({
        payload: {
          id: gameId,
        },
        access_token: cookies["bs-access-token"],
      })
    );
    if (result.error == undefined) {
      const set_data: Array<PlayerOptions> = [];
      for (let i = 0; i < result.payload.length; i++) {
        set_data.push({
          label: result.payload[i].character_name,
          id: result.payload[i].id,
        });
      }
      setCharacterDatas(set_data);
    }
  };
  const fetchGameMasterData = async (gameId: number) => {
    const result = await dispatch<ReturnType<typeof getgamedatamaster>>(
      getgamedatamaster({
        payload: {
          id: gameId,
        },
        access_token: cookies["bs-access-token"],
      })
    );
    return result.payload;
  };
  const [player1Detail, setPlayer1Detail] = React.useState<GameMasterData[]>(
    []
  );
  const [player2Detail, setPlayer2Detail] = React.useState<GameMasterData[]>(
    []
  );

  const fetchMatchData = async (matchData) => {
    if (matchData != null) {
      const _game_detail_data = await fetchGameMasterData(matchData?.game_id);
      const match_info: MatchInfo = JSON.parse(matchData.match_info);
      if (match_info != undefined) {
        setPlayer1Value(match_info.player1_id ?? 0);
        setPlayer2Value(match_info.player2_id ?? 0);
        setPlayer1NameValue(match_info.player1_name ?? "");
        setPlayer2NameValue(match_info.player2_name ?? "");

        setCharacter1Value(match_info.player1_character_id ?? 0);
        setCharacter2Value(match_info.player2_character_id ?? 0);
        setCharacter1Num(match_info.player1_character_num ?? 0);
        setCharacter2Num(match_info.player2_character_num ?? 0);
        
        setCharacter1Value2(match_info.player1_character_id2 ?? 0);
        setCharacter2Value2(match_info.player2_character_id2 ?? 0);
        setCharacter1Num2(match_info.player1_character_num2 ?? 0);
        setCharacter2Num2(match_info.player2_character_num2 ?? 0);

        setCharacter1Value3(match_info.player1_character_id3 ?? 0);
        setCharacter2Value3(match_info.player2_character_id3 ?? 0);
        setCharacter1Num3(match_info.player1_character_num3 ?? 0);
        setCharacter2Num3(match_info.player2_character_num3 ?? 0);

        setPlayer1WinNum(match_info.player1_score ?? 0);
        setPlayer2WinNum(match_info.player2_score ?? 0);
        //_game_detail_dataの中にある要素をfor-inで回す
        init_game_data(match_info, _game_detail_data);
      } else {
        init_game_data(undefined, _game_detail_data);
        initialize();
      }
      setMovieUrl(matchData.movie_url ?? "");
      fetchPlayerData();
      fetchGameCharacterData(matchData?.game_id);
    }
  };
  React.useEffect(() => {
    fetchMatchData(matchData);
  }, [id, matchData]);

  const fetchData = async () => {
    const result = await dispatch<ReturnType<typeof getmatchdata>>(
      getmatchdata({
        payload: {
          id: id,
          tournament_id: tid,
        },
        access_token: cookies["bs-access-token"],
      })
    );
    if (result.error != undefined) {
      return result.payload;
    }
  };
  const init_game_data = (match_info, _game_detail_data) => {
    const _set_player1_detaildata: GameMasterData[] = [];
    for (const key in _game_detail_data) {
      const gd_data: GameMasterData = _game_detail_data[key];
      if (gd_data.master_name != undefined) {
        const player1_detail_keydata =
          match_info?.player1_detail[gd_data.master_name];
        if (
          match_info?.player1_detail != undefined &&
          player1_detail_keydata != undefined
        ) {
          _set_player1_detaildata.push({
            master_name: gd_data.master_name,
            value: player1_detail_keydata,
          });
        } else {
          _set_player1_detaildata.push({
            master_name: gd_data.master_name,
            value: gd_data.default_value,
          });
        }
      }
    }
    setPlayer1Detail(_set_player1_detaildata);
    const _set_player2_detaildata: GameMasterData[] = [];
    for (const key in _game_detail_data) {
      const gd_data: GameMasterData = _game_detail_data[key];
      if (gd_data.master_name != undefined) {
        const player2_detail_keydata =
          match_info?.player2_detail[gd_data.master_name];
        if (
          match_info?.player2_detail != undefined &&
          player2_detail_keydata != undefined
        ) {
          _set_player2_detaildata.push({
            master_name: gd_data.master_name,
            value: player2_detail_keydata,
          });
        } else {
          _set_player2_detaildata.push({
            master_name: gd_data.master_name,
            value: gd_data.default_value,
          });
        }
      }
    }
    setPlayer2Detail(_set_player2_detaildata);
  };
  const initialize = async () => {
    setPlayer1Value(0);
    setPlayer2Value(0);
    setPlayer1NameValue("");
    setPlayer2NameValue("");
    setCharacter1Value(0);
    setCharacter2Value(0);
    setCharacter1Num(0);
    setCharacter2Num(0);

    setCharacter1Value2(0);
    setCharacter2Value2(0);
    setCharacter1Num2(0);
    setCharacter2Num2(0);

    setCharacter1Value3(0);
    setCharacter2Value3(0);
    setCharacter1Num3(0);
    setCharacter2Num3(0);

    setPlayer1WinNum(0);
    setPlayer2WinNum(0);

    setMovieUrl("");
  };
  React.useEffect(() => {
    initialize();
    fetchData();
  }, []);

  return (
    <div>
      <Breadcrumbs aria-label="breadcrumb">
        <Link to="/site/tournament/">トーナメント一覧</Link>
        <Link to={"/site/regist/match/" + tid + "/"}>試合一覧</Link>
        <Link>
          試合情報 /TID:{tid}/MID:{id}{" "}
        </Link>
      </Breadcrumbs>
      <h2>試合情報管理</h2>
      <Box
        sx={{ backgroundColor: "white", width: 800, margin: "0 auto", pb: 2 }}
      >
        <div
          style={{
            width: 400,
            height: 210,
            border: "1px solid black",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {movieUrl != null ? (
            <YoutubePlayer videoId={getVideoIdFromUrl(movieUrl)} />
          ) : (
            ""
          )}
        </div>
        <Box sx={{ pl: 1, pt: 1 }}>
          {movieUrl}
          <Button
            sx={{ ml: 1 }}
            onClick={handleSettingMovieUrl}
            variant="contained"
          >
            動画URL設定
          </Button>
        </Box>
        <Box sx={{ overflow: "auto", maxHeight: 600, mt: 1, pb: 10 }}>
          <Grid container>
            <Grid sx={{ pl: 1, pt: 2 }} xs>
              <Box sx={{ mb: 1 }}>
                <Autocomplete
                  id="combo-box-player1"
                  value={
                    playerDatas.find((player) => player.id === player1Value) ??
                    null
                  }
                  onChange={(event: any, newValue: PlayerOptions | null) => {
                    setPlayer1Value(newValue?.id ?? null);
                    setPlayer1NameValue(newValue?.label ?? "");
                  }}
                  isOptionEqualToValue={isOptionEqualToValue}
                  options={playerDatas}
                  sx={{ width: 300 }}
                  renderInput={(params) => (
                    <TextField {...params} label="プレイヤー1" />
                  )}
                />
              </Box>
              <Box sx={{ mb: 2 }}>
                <TextField
                  value={player1WinNum}
                  onChange={(event) => setPlayer1WinNum(event.target.value)}
                  sx={{ width: 100 }}
                  id="player1_win"
                  type="number"
                  label="勝利数"
                  variant="standard"
                />
              </Box>
              <Grid container>
                <Grid sx={{ pl: 1, pt: 2 }}>
                  <Box sx={{ mb: 1 }}>
                    <Autocomplete
                      id="combo-box-character1"
                      value={
                        characterDatas.find(
                          (player) => player.id === character1Value
                        ) ?? null
                      }
                      onChange={(event: any, newValue: PlayerOptions | null) => {
                        setCharacter1Value(newValue?.id);
                      }}
                      isOptionEqualToValue={isOptionEqualToValue}
                      options={characterDatas}
                      sx={{ width: 200 }}
                      renderInput={(params) => (
                        <TextField {...params} label="キャラクター1" />
                      )}
                    />
                  </Box>
                </Grid>
                <Grid sx={{ pl: 1, pt: 2 }} xs>
                  <Box sx={{ mb: 1 }}>
                    <TextField
                      value={character1Num}
                      onChange={(event) => setCharacter1Num(event.target.value)}
                      sx={{ width: 100 }}
                      id="character1Num"
                      type="number"
                      label="使用数"
                      variant="standard"
                    />
                  </Box>
                </Grid>
              </Grid>
              <Grid container>
                <Grid sx={{ pl: 1, pt: 2 }}>
                  <Box sx={{ mb: 1 }}>
                    <Autocomplete
                      id="combo-box-character2"
                      value={
                        characterDatas.find(
                          (player) => player.id === character1Value2
                        ) ?? null
                      }
                      onChange={(event: any, newValue: PlayerOptions | null) => {
                        setCharacter1Value2(newValue?.id);
                      }}
                      isOptionEqualToValue={isOptionEqualToValue}
                      options={characterDatas}
                      sx={{ width: 200 }}
                      renderInput={(params) => (
                        <TextField {...params} label="キャラクター2" />
                      )}
                    />
                  </Box>
                </Grid>
                <Grid sx={{ pl: 1, pt: 2 }} xs>
                  <Box sx={{ mb: 1 }}>
                    <TextField
                      value={character1Num2}
                      onChange={(event) => setCharacter1Num2(event.target.value)}
                      sx={{ width: 100 }}
                      id="character1Num2"
                      type="number"
                      label="使用数"
                      variant="standard"
                    />
                  </Box>
                </Grid>
              </Grid>
              <Grid container>
                <Grid sx={{ pl: 1, pt: 2 }}>
                  <Box sx={{ mb: 1 }}>
                    <Autocomplete
                      id="combo-box-character3"
                      value={
                        characterDatas.find(
                          (player) => player.id === character1Value3
                        ) ?? null
                      }
                      onChange={(event: any, newValue: PlayerOptions | null) => {
                        setCharacter1Value3(newValue?.id);
                      }}
                      isOptionEqualToValue={isOptionEqualToValue}
                      options={characterDatas}
                      sx={{ width: 200 }}
                      renderInput={(params) => (
                        <TextField {...params} label="キャラクター3" />
                      )}
                    />
                  </Box>
                </Grid>
                <Grid sx={{ pl: 1, pt: 2 }} xs>
                  <Box sx={{ mb: 1 }}>
                    <TextField
                      value={character1Num3}
                      onChange={(event) => setCharacter1Num3(event.target.value)}
                      sx={{ width: 100 }}
                      id="character1Num3"
                      type="number"
                      label="使用数"
                      variant="standard"
                    />
                  </Box>
                </Grid>
              </Grid>
              <Divider sx={{ mb: 2 }} />
              {game_master_data != undefined
                ? game_master_data.map((data) => (
                  <Box
                    sx={{ mb: 1 }}
                    style={{ display: "flex", alignItems: "center" }}
                    key={data.master_name}
                  >
                    <TextField
                      value={
                        player1Detail.find(
                          (detail) => detail.master_name === data.master_name
                        )?.value ?? 0
                      }
                      onChange={(event) => {
                        const _player1_detail = player1Detail;
                        const _index = _player1_detail.findIndex(
                          (detail) => detail.master_name === data.master_name
                        );
                        if (_index != -1) {
                          _player1_detail[_index].value = parseInt(
                            event.target.value
                          );
                          setPlayer1Detail([..._player1_detail]);
                        }
                      }}
                      sx={{ width: 300 }}
                      id={"player1_" + data.master_name}
                      type="number"
                      label={data.master_display_name}
                      variant="standard"
                    />
                  </Box>
                ))
                : ""}
            </Grid>
            <Grid sx={{ pl: 1, pt: 2 }} xs>
              <Box sx={{ mb: 1 }}>
                <Autocomplete
                  id="combo-box-player2"
                  value={
                    playerDatas.find((player) => player.id === player2Value) ??
                    null
                  }
                  onChange={(event: any, newValue: PlayerOptions | null) => {
                    setPlayer2Value(newValue?.id ?? null);
                    setPlayer2NameValue(newValue?.label ?? "");
                  }}
                  options={playerDatas}
                  isOptionEqualToValue={isOptionEqualToValue}
                  sx={{ width: 300 }}
                  renderInput={(params) => (
                    <TextField {...params} label="プレイヤー2" />
                  )}
                />
              </Box>
              <Box sx={{ mb: 2 }}>
                <TextField
                  value={player2WinNum}
                  onChange={(event) => setPlayer2WinNum(event.target.value)}
                  sx={{ width: 100 }}
                  id="player2_win"
                  type="number"
                  label="勝利数"
                  variant="standard"
                />
              </Box>
              <Grid container>
                <Grid sx={{ pl: 1, pt: 2 }}>
                  <Box sx={{ mb: 1 }}>
                    <Autocomplete
                      id="combo-box-character4"
                      value={
                        characterDatas.find(
                          (player) => player.id === character2Value
                        ) ?? null
                      }
                      onChange={(event: any, newValue: PlayerOptions | null) => {
                        setCharacter2Value(newValue?.id);
                      }}
                      isOptionEqualToValue={isOptionEqualToValue}
                      options={characterDatas}
                      sx={{ width: 200 }}
                      renderInput={(params) => (
                        <TextField {...params} label="キャラクター1" />
                      )}
                    />
                  </Box>
                </Grid>
                <Grid sx={{ pl: 1, pt: 2 }} xs>
                  <Box sx={{ mb: 1 }}>
                    <TextField
                      value={character2Num}
                      onChange={(event) => setCharacter2Num(event.target.value)}
                      sx={{ width: 100 }}
                      id="character2Num"
                      type="number"
                      label="使用数"
                      variant="standard"
                    />
                  </Box>
                </Grid>
              </Grid>
              <Grid container>
                <Grid sx={{ pl: 1, pt: 2 }}>
                  <Box sx={{ mb: 1 }}>
                    <Autocomplete
                      id="combo-box-character5"
                      value={
                        characterDatas.find(
                          (player) => player.id === character2Value2
                        ) ?? null
                      }
                      onChange={(event: any, newValue: PlayerOptions | null) => {
                        setCharacter2Value2(newValue?.id);
                      }}
                      isOptionEqualToValue={isOptionEqualToValue}
                      options={characterDatas}
                      sx={{ width: 200 }}
                      renderInput={(params) => (
                        <TextField {...params} label="キャラクター2" />
                      )}
                    />
                  </Box>
                </Grid>
                <Grid sx={{ pl: 1, pt: 2 }} xs>
                  <Box sx={{ mb: 1 }}>
                    <TextField
                      value={character2Num2}
                      onChange={(event) => setCharacter2Num2(event.target.value)}
                      sx={{ width: 100 }}
                      id="character2Num2"
                      type="number"
                      label="使用数"
                      variant="standard"
                    />
                  </Box>
                </Grid>
              </Grid>
              <Grid container>
                <Grid sx={{ pl: 1, pt: 2 }}>
                  <Box sx={{ mb: 1 }}>
                    <Autocomplete
                      id="combo-box-character6"
                      value={
                        characterDatas.find(
                          (player) => player.id === character2Value3
                        ) ?? null
                      }
                      onChange={(event: any, newValue: PlayerOptions | null) => {
                        setCharacter2Value3(newValue?.id);
                      }}
                      isOptionEqualToValue={isOptionEqualToValue}
                      options={characterDatas}
                      sx={{ width: 200 }}
                      renderInput={(params) => (
                        <TextField {...params} label="キャラクター3" />
                      )}
                    />
                  </Box>
                </Grid>
                <Grid sx={{ pl: 1, pt: 2 }} xs>
                  <Box sx={{ mb: 1 }}>
                    <TextField
                      value={character2Num3}
                      onChange={(event) => setCharacter2Num3(event.target.value)}
                      sx={{ width: 100 }}
                      id="character2Num3"
                      type="number"
                      label="使用数"
                      variant="standard"
                    />
                  </Box>
                </Grid>
              </Grid>
              <Divider sx={{ mb: 2 }} />
              {game_master_data != undefined
                ? game_master_data.map((data) => (
                  <Box
                    sx={{ mb: 1 }}
                    style={{ display: "flex", alignItems: "center" }}
                    key={data.master_name}
                  >
                    <TextField
                      value={
                        player2Detail.find(
                          (detail) => detail.master_name === data.master_name
                        )?.value ?? 0
                      }
                      onChange={(event) => {
                        const _player2_detail = player2Detail;
                        const _index = _player2_detail.findIndex(
                          (detail) => detail.master_name === data.master_name
                        );
                        if (_index != -1) {
                          _player2_detail[_index].value = parseInt(
                            event.target.value
                          );
                          setPlayer2Detail([..._player2_detail]);
                        }
                      }}
                      sx={{ width: 300 }}
                      id={"player2_" + data.master_name}
                      type="number"
                      label={data.master_display_name}
                      variant="standard"
                    />
                  </Box>
                ))
                : ""}
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
          <Button onClick={handleSaveData} variant="contained">
            データ保存
          </Button>
        </Box>
      </Box>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>URL登録</DialogTitle>
        <DialogContent>
          <DialogContentText>
            この試合のYoutubeURLを登録してください。
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            value={movieUrl}
            onChange={(event) => setMovieUrl(event.target.value)}
            id="url_text"
            label="URL"
            type="text"
            sx={{ width: 500 }}
            variant="standard"
          />
          <br />
          ※https://～から設定してください。
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>キャンセル</Button>
          <Button onClick={handleRegistURL}>登録</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default MatchEdit;
