import { BaseYup } from "./BaseYup";

const scheme = BaseYup.object().shape({
  name: BaseYup.string().required().max(255),
  email: BaseYup.string().required().email(),
  password: BaseYup.string().required().min(8).max(20),
  passwordConfirmation: BaseYup.string().oneOf([BaseYup.ref("password"), null]),
});

export const LoginScheme = scheme;
