import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import EventIcon from "@mui/icons-material/Event";
import SportsEsportsIcon from "@mui/icons-material/SportsEsports";
import FaceIcon from '@mui/icons-material/Face';
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import * as React from "react";
import { Link } from "react-router-dom";

export const mainListItems = (
  <React.Fragment>
    <ListSubheader component="div" inset>
      サイト
    </ListSubheader>
    <ListItemButton component={Link} to="/site/tournament">
      <ListItemIcon>
        <EmojiEventsIcon />
      </ListItemIcon>
      <ListItemText primary="トーナメント一覧" />
    </ListItemButton>
    <ListItemButton component={Link} to="/site/event">
      <ListItemIcon>
        <EventIcon />
      </ListItemIcon>
      <ListItemText primary="イベント一覧" />
    </ListItemButton>
    <ListItemButton component={Link} to="/site/player">
      <ListItemIcon>
        <DirectionsRunIcon />
      </ListItemIcon>
      <ListItemText primary="プレイヤー" />
    </ListItemButton>
  </React.Fragment>
);

export const secondaryListItems = (
  <React.Fragment>
    <ListSubheader component="div" inset>
      管理
    </ListSubheader>
    <ListItemButton component={Link} to="/admin/user">
      <ListItemIcon>
        <AccountCircleIcon />
      </ListItemIcon>
      <ListItemText primary="ユーザー" />
    </ListItemButton>
    <ListItemButton component={Link} to="/admin/game">
      <ListItemIcon>
        <SportsEsportsIcon />
      </ListItemIcon>
      <ListItemText primary="ゲーム" />
    </ListItemButton>
    <ListItemButton component={Link} to="/admin/ai">
      <ListItemIcon>
        <FaceIcon />
      </ListItemIcon>
      <ListItemText primary="AI" />
    </ListItemButton>
  </React.Fragment>
);
