import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import merge from "lodash/merge";

import { GameCharacter, GameMasterData, UserData } from "./definition";
import {
  fetchGameData,
  fetchGameList,
  fetchUserList,
  getgamecharacters,
  getgamecharactersone,
  getgamedatamaster,
  getgamedatamasterone,
  registGameData,
} from "./operations";

type State = {
  data: object | null;
  gameData: Array<object>;
  gameOneData: object | null;
  characterData: Array<GameCharacter>;
  charaqcterOneData: GameCharacter | null;
  gameMasterData: Array<GameMasterData>;
  gameOneMasterData: GameMasterData | null;
  userData: Array<UserData>;
  status: string;
  error: string;
};

const initialState: State = {
  data: null,
  gameData: [],
  gameOneData: null,
  characterData: [],
  charaqcterOneData: null,
  gameMasterData: [],
  gameOneMasterData: null,
  userData: [],
  status: "",
  error: "",
};

const slice = createSlice({
  name: "admin",
  initialState,
  reducers: {
    initializeState: (state) => {
      return Object.assign({}, initialState);
    },
    setState: (state, action: PayloadAction<Partial<State>>) => {
      Object.keys(action.payload).map((key) => {
        merge(state, { [key]: action.payload[key] });
      });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(registGameData.pending, (state) => {
      state.status = "pending";
    });
    builder.addCase(registGameData.fulfilled, (state, action) => {
      state.gameOneData = action.payload;
      state.status = "succeeded";
    });
    builder.addCase(registGameData.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    });
    builder.addCase(fetchGameData.pending, (state) => {
      state.status = "pending";
    });
    builder.addCase(fetchGameData.fulfilled, (state, action) => {
      state.gameOneData = action.payload;
      state.status = "succeeded";
    });
    builder.addCase(fetchGameList.pending, (state) => {
      state.status = "pending";
    });
    builder.addCase(fetchGameList.fulfilled, (state, action) => {
      state.gameData = action.payload;
      state.status = "succeeded";
    });
    builder.addCase(getgamecharacters.pending, (state) => {
      state.status = "pending";
    });
    builder.addCase(getgamecharacters.fulfilled, (state, action) => {
      state.characterData = action.payload;
      state.status = "succeeded";
    });
    builder.addCase(getgamecharactersone.fulfilled, (state, action) => {
      state.charaqcterOneData = action.payload;
      state.status = "succeeded";
    });
    builder.addCase(getgamedatamaster.pending, (state) => {
      state.status = "pending";
    });
    builder.addCase(getgamedatamaster.fulfilled, (state, action) => {
      state.gameMasterData = action.payload;
      state.status = "succeeded";
    });
    builder.addCase(getgamedatamasterone.pending, (state) => {
      state.status = "pending";
    });
    builder.addCase(getgamedatamasterone.fulfilled, (state, action) => {
      state.gameOneMasterData = action.payload;
      state.status = "succeeded";
    });
    builder.addCase(fetchUserList.pending, (state) => {
      state.status = "pending";
    });
    builder.addCase(fetchUserList.fulfilled, (state, action) => {
      state.userData = action.payload;
      state.status = "succeeded";
    });
  },
});

export default slice.reducer;

export const { initializeState, setState } = slice.actions;
