import React from "react";
import { Box } from "@mui/system";
import { Breadcrumbs, Button, TextField } from "@mui/material/";
import { useCookies } from "react-cookie";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { OpenAI } from "langchain/llms/openai";
import { initializeAgentExecutorWithOptions } from "langchain/agents";
import { ChatOpenAI } from "langchain/chat_models/openai";
import { SerpAPI } from "langchain/tools";
import { Calculator } from "langchain/tools/calculator";
import { useSelector } from "store/configureStore";
import { setState as setCommonState } from "../../store/Common/information";

interface Props {}

const Aisystem: React.FC<Props> = () => {
  const openAIApiKey = "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";
  const llm = new OpenAI({
    openAIApiKey: openAIApiKey,
    temperature: 0.9,
  });
  const loading: boolean = useSelector((state) => state.information.loading);
  const dispatch = useDispatch();
  const [cookies, setCookie, removeCookie] = useCookies(["bs-token"]);
  const navigate = useNavigate();

  const [inputText, setInputText] = React.useState("");
  const [answerText, setAnswerText] = React.useState("");
  const handleExecuteChat = async () => {
      
    const executor = await initializeAgentExecutorWithOptions(
      [new Calculator(), new SerpAPI(openAIApiKey)],
      new ChatOpenAI({ openAIApiKey: openAIApiKey,modelName: "gpt-3.5-turbo-0613", temperature: 0 }),
      {
        
        agentType: "openai-functions",
        verbose: true,
      }
    );
    dispatch(setCommonState({ loading: true }));
    const result = await executor.run(inputText);
    setAnswerText(result);
    dispatch(setCommonState({ loading: false }));
  };

  React.useEffect(() => {
  }, []);

  return (
    <div>
      <h2>Aisystem v0.1</h2>
      <Box>
      <TextField
          id="tournament_data_text"
          sx={{ width: 640 }}
          value={inputText}
          onChange={(event) => setInputText(event.target.value)}
          multiline
          rows={6}
          label="質問"
          variant="outlined"
        />
      </Box>
      <Box sx={{ mt: 1 }}>
        <Button
            onClick={handleExecuteChat}
            style={{ backgroundColor: "#FFA500" }}
            variant="contained"
          >
          Aiに質問する
        </Button>
      </Box>
      <Box sx={{ mt: 2 }}>
      <TextField
          id="tournament_data_text"
          sx={{ width: 640 }}
          value={answerText}
          onChange={(event) => setAnswerText(event.target.value)}
          multiline
          rows={10}
          label="回答"
          variant="outlined"
        />
      </Box>
    </div>
  )
}

export default Aisystem;
