export const LocaleJP = {
  mixed: {
    required: "必須項目です",
    notType: "入力値が不正です",
  },
  string: {
    min: "${min}文字以上で入力してください",
    max: "${max}文字以下で入力してください",
    email: "メールアドレスの形式で入力してください",
    url: "URLの形式で入力してください",
    trim: "前後にスペースがあります",
    lowercase: "小文字で入力してください",
    uppercase: "大文字で入力してください",
  },
  number: {
    min: "${min}以上の値を入力してください",
    max: "${max}以下の値を入力してください",
    lessThan: "${less}未満の値を入力してください",
    moreThan: "${more}より大きい値を入力してください",
    positive: "正の値を入力してください",
    negative: "負の値を入力してください",
    integer: "整数を入力してください",
  },
  date: {
    min: "${min}以降の日付を入力してください",
    max: "${max}以前の日付を入力してください",
  },
  array: {
    min: "${min}個以上の項目を入力してください",
    max: "${max}個以下の項目を入力してください",
  },
  object: {
    noUnknown: "不正な値が入力されています",
  },
  boolean: {},
};
