import { yupResolver } from "@hookform/resolvers/yup";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import React from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useSelector } from "store/configureStore";
import { fetchRegist } from "store/Login/operations";

import { LoginScheme } from "../../util/validation/CreateUserValidate";

interface Props {}

const CreateUser: React.FC<Props> = () => {
  const dispatch = useDispatch();
  const loading: boolean = useSelector((state) => state.information.loading);

  const {
    register,
    handleSubmit,
    formState: { isDirty, errors },
  } = useForm({
    mode: "onChange",
    criteriaMode: "all",
    resolver: yupResolver(LoginScheme),
  });
  const onSubmit = async (data: any) => {
    if (!loading) {
      await dispatch<ReturnType<typeof fetchRegist>>(
        fetchRegist({
          payload: {
            name: data.name,
            email: data.email,
            password: data.password,
          },
        })
      );
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 4, bgcolor: "secondary.main" }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          ユーザー登録(臨時)
        </Typography>
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          noValidate
          sx={{ width: 400, mt: 1 }}
        >
          <TextField
            margin="normal"
            required
            fullWidth
            id="name"
            label="Name"
            name="name"
            autoComplete="name"
            autoFocus
            {...register("name")}
          />
          <Typography component="p" style={{ color: "red" }}>
            {errors.name?.message}
          </Typography>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            {...register("email")}
          />
          <Typography component="p" style={{ color: "red" }}>
            {errors.email?.message}
          </Typography>
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            {...register("password")}
          />
          <Typography component="p" style={{ color: "red" }}>
            {errors.password?.message}
          </Typography>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            disabled={!isDirty || Object.keys(errors).length > 0}
            sx={{ mt: 3, mb: 2 }}
          >
            ユーザー登録
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default CreateUser;
