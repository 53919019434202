import { CssBaseline } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import React from "react";
import { StrictMode } from "react";
import { CookiesProvider } from "react-cookie";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { StyleSheetManager } from "styled-components";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import App from "./App";
import store from "./store/configureStore";
import theme from "./theme";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

interface Props {
  children: React.ReactNode;
}

const MuiThemeProvider: React.FC<Props> = (props: Props) => {
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
    <CookiesProvider>
      <ThemeProvider theme={theme}>
        <StyleSheetManager shouldForwardProp={(prop) => prop !== "theme"}>
          <CssBaseline />
          {props.children}
        </StyleSheetManager>
      </ThemeProvider>
    </CookiesProvider>
    </LocalizationProvider>
  );
};

const container = document.getElementById("root");
const root = createRoot(container); // createRoot(container!) if you use TypeScript

root.render(
  <MuiThemeProvider>
    <Provider store={store}>
      <App />
    </Provider>
  </MuiThemeProvider>
);
