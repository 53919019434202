import { Breadcrumbs, Button } from "@mui/material/";
import { Box } from "@mui/system";
import { DataGrid, GridColDef, GridRowsProp } from "@mui/x-data-grid";
import React from "react";
import { useCookies } from "react-cookie";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useSelector } from "store/configureStore";

import ActionCell from "../../component/datagrid/PlayerListGridAction";
import ImageCell from "../../component/datagrid/GridImage";
import { setState as setCommonState } from "../../store/Common/information";
import store from "../../store/configureStore";
import {
  getPlayerList,
} from "../../store/Site/operations";
import { editarticle, getplayerpersonalanalytics, makebattlescorearticle } from "../../store/Analytics/operations";

interface Props {}

const PLayerList: React.FC<Props> = () => {
  const dispatch = useDispatch();
  const [cookies, setCookie, removeCookie] = useCookies(["bs-token"]);
  const data: boolean = useSelector((state) => state.site.playerlistData);
  
  const [allPlayerData, setAllPlayerData] = React.useState([]);
  const navigate = useNavigate();

  const columns: GridColDef[] = [
    { field: "custom_id", headerName: "プレイヤーID", width: 250 },    
    {
      field: "detail_imgpath",
      headerName: "プレイヤー画像",
      width: 250,
      renderCell: (params) => <ImageCell value={params.row?.detail?.imgpath} />,
    },
    { field: "name", headerName: "プレイヤー名", width: 250 },
    {
      field: "actions",
      headerName: "操作",
      width: 200,
      renderCell: (params) => (
        <ActionCell
          onPlayerRegist={() => handlePlayerRegist(params.row.id)}
          onDelete={() => handleDelete(params.row.custom_id)}
          onAnalytics={() => handleAnalytics(params.row.custom_id)}
        />
      ),
    },
  ];

  const handlePlayerRegist = (id: number) => {
    navigate(`/site/player/edit/${id}`);
  };
  const handleAnalytics = (custom_id: string) => {
    navigate(`/site/common/analytics/player/${custom_id}`);
  };
  const handleDelete = (custom_id: string) => {
  };

  const updateallplayerdata = async () => {
    let _error_custom_id:Array<string> = [];
      
    for (const player of allPlayerData) {
      //updatePlayerArticleの処理を同期的に実行するために、awaitをつける
      console.log(player.custom_id);
      _error_custom_id = await updatePlayerArticle(player,_error_custom_id);
    }    
    store.dispatch(
      setCommonState({
        alertDialogOpen: true,
        alertDialogType: "success",
        alertDialogMessage: "データ更新を完了しました。ログにエラーが起こったcustom_idが表示されます。",
        confirmDialogOpen: false,
      })
    );
    console.log(_error_custom_id);
  };

  const updatePlayerArticle = async (player,_error_custom_id) => {
    const player_analytics_result = await dispatch<ReturnType<typeof getplayerpersonalanalytics>>(
      getplayerpersonalanalytics({
        payload: {
          custom_id: player.custom_id,
        },
        access_token: cookies["bs-access-token"],
      })
    );
    if(player_analytics_result?.error == undefined && player_analytics_result.payload!=undefined){
      const send_payload_data = {        
        article_type: "player",
        custom_id: player.custom_id,
        analytics_data:player_analytics_result.payload,
      };        
      const result = await dispatch<ReturnType<typeof editarticle>>(
        editarticle({
          payload: send_payload_data,
          access_token: cookies["bs-access-token"],
        })
      );
      if(result.error == undefined){
        if(result.payload?.id != undefined){
          const result2 = await dispatch<ReturnType<typeof makebattlescorearticle>>(
            makebattlescorearticle({
              payload: {
                article_id: result.payload?.id,
              },
              access_token: cookies["bs-access-token"],
            })
          );
        }else {
          _error_custom_id.push(player.custom_id);
        }
      } else {
        _error_custom_id.push(player.custom_id);
      }
    } else {
      _error_custom_id.push(player.custom_id);
    }
    return _error_custom_id;
  }
  const fetchData = async () => {
    const result = await dispatch<ReturnType<typeof getPlayerList>>(
      getPlayerList(cookies["bs-access-token"])
    );
    setAllPlayerData(result.payload);
  };
  React.useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      <Breadcrumbs aria-label="breadcrumb">
        <Link>プレイヤー一覧</Link>
      </Breadcrumbs>
      <h2>
        プレイヤー一覧        
      </h2>
      <div style={{ height: 50 }}>
        <Button
          variant="contained"
          onClick={updateallplayerdata}
        >
          プレイヤー情報更新
        </Button>
      </div>
      <Box sx={{ backgroundColor: "white" }}>
        <DataGrid rowHeight={100} rows={data} columns={columns} />
      </Box>
    </div>
  );
};

export default PLayerList;


