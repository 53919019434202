import { Button, TextField, Typography } from "@mui/material";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { Box } from "@mui/system";
import React, { useRef, useState } from "react";
import { useAsyncCallback } from "react-async-hook";
import { useCookies } from "react-cookie";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "store/configureStore";

import noimage from "../../../assets/noimage.png";
import CircularIntegration from "../../component/parts/CircularIntegration";
import { IMAGE_DOMAIN } from "../../const/constatns";
import { fetchGameList } from "../../store/Admin/operations";
import { setState as setCommonState } from "../../store/Common/information";
import store from "../../store/configureStore";
import { fetchOrganizer, fetchUser } from "../../store/Login/operations";
import { EventData, tournamentListData } from "../../store/Site/definition";
import { editEventData, fetchEventData } from "../../store/Site/operations";

interface Props {}

const EventEdit: React.FC<Props> = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [cookies, setCookie, removeCookie] = useCookies(["bs-token"]);
  const [roleName, seteRoleName] = useState("新規登録");
  const data: EventData = useSelector((state) => state.site.eventOneData);
  const gamelist: boolean = useSelector((state) => state.admin.gameData);
  const organizerlist: boolean = useSelector(
    (state) => state.login.organizerData
  );
  const { id } = useParams();

  const [eventId, setEventId] = useState(null);
  const [uploadFileData, setUploadFileData] = useState(null);
  const [previewData, setPreviewData] = useState(null);
  const [eventName, setEventName] = useState("");
  const [selectGameId, setSelectGameId] = useState("0");
  const [selectOrganizerId, setSelectOrganizerId] = useState("0");

  const inputRef = useRef(null);
  const [success, setSuccess] = useState(false);

  const uploadFile = async (file) => {
    if (!file) return;
    setUploadFileData(file);
    setPreviewData(window.URL.createObjectURL(file));
    setSuccess(true);
  };

  const onFileInputChange = async (event) => {
    const file = event.target.files[0];
    await uploadFile(file);
  };

  const clickFileUploadButton = () => {
    setSuccess(false);
    inputRef.current.click();
  };

  const asyncEvent = useAsyncCallback(onFileInputChange);
  const handleChange = (event: SelectChangeEvent) => {
    setSelectGameId(event.target.value as string);
  };
  const handleOrgnizerChange = (event: SelectChangeEvent) => {
    setSelectOrganizerId(event.target.value as string);
  };

  const handleEdit = async () => {
    const result = await dispatch<ReturnType<typeof editEventData>>(
      editEventData({
        payload: {
          id: id,
          eventname: eventName,
          game_id: selectGameId,
          file: uploadFileData,
          organizer_id: selectOrganizerId,
        },
        access_token: cookies["bs-access-token"],
      })
    );
    if (result.payload["id"] != undefined) {
      store.dispatch(
        setCommonState({
          alertDialogOpen: true,
          alertDialogMessage: `イベントデータの${roleName}を行いました`,
          alertDialogType: "success",
        })
      );
      store.dispatch(setCommonState({}));

      setEventId(result.payload["id"]);
      navigate(`/site/event/edit/${result.payload["id"]}`, { replace: true });
    } else {
      store.dispatch(
        setCommonState({
          alertDialogOpen: true,
          alertDialogMessage: `イベントデータの${roleName}に失敗しました。`,
          alertDialogType: "error",
        })
      );
    }
  };
  const fetchData = async () => {
    const result = await dispatch<ReturnType<typeof fetchEventData>>(
      fetchEventData({
        payload: {
          id: eventId,
        },
        access_token: cookies["bs-access-token"],
      })
    );
  };
  const fetchGameListData = async () => {
    const result = await dispatch<ReturnType<typeof fetchGameList>>(
      fetchGameList(cookies["bs-access-token"])
    );
  };
  const fetchOrganizerListData = async () => {
    const result = await dispatch<ReturnType<typeof fetchOrganizer>>(
      fetchOrganizer({
        payload: {},
        access_token: cookies["bs-access-token"],
      })
    );
  };

  React.useEffect(() => {
    if (data != null) {
      setEventName(data.eventname);
      setSelectGameId(data.game_id);
      if (data.organizer_id != null) setSelectOrganizerId(data.organizer_id);
      else setSelectOrganizerId("0");
      if (data.imgpath != null) {
        setPreviewData(IMAGE_DOMAIN + data.imgpath);
      }
    }
  }, [data]);

  React.useEffect(() => {
    if (eventId != undefined) {
      fetchData();
      seteRoleName("編集");
    } else {
      seteRoleName("新規登録");
    }
  }, [eventId]);

  React.useEffect(() => {
    setEventName("");
    setSelectGameId("0");
    setSelectOrganizerId("0");

    if (id != undefined) setEventId(id);
    fetchGameListData();
    fetchOrganizerListData();
  }, []);

  return (
    <div>
      <Breadcrumbs aria-label="breadcrumb">
        <Link to="/site/event">イベント一覧</Link>
        <Link>{roleName}</Link>
      </Breadcrumbs>
      <h2>イベントデータ{roleName}</h2>
      {eventId != undefined ? (
        <Typography variant="subtitle1">ID: {eventId}</Typography>
      ) : (
        ""
      )}
      <Box sx={{ mt: 1 }}>
        <TextField
          id="event_data_name"
          sx={{ width: 400 }}
          value={eventName}
          onChange={(event) => setEventName(event.target.value)}
          label="イベント名"
          variant="outlined"
        />
      </Box>
      <Box sx={{ mt: 1 }}>
        <Select
          labelId="select-gamelist-label"
          id="select-gamelist-label"
          value={selectGameId}
          label="ゲーム選択"
          onChange={handleChange}
        >
          <MenuItem key="0" value="0">
            ゲームを選択してください
          </MenuItem>
          {gamelist.map((game) => (
            <MenuItem key={game.id} value={game.id}>
              {game.name}
            </MenuItem>
          ))}
        </Select>
      </Box>
      <Box sx={{ mt: 1 }}>
        <img height={150} src={previewData == null ? noimage : previewData} />
        <CircularIntegration
          onClick={clickFileUploadButton}
          asyncEvent={asyncEvent}
          success={success}
          component="label"
          text={asyncEvent.loading ? "..." : "イベントサムネイル画像を選択"}
        />
        <input
          hidden
          ref={inputRef}
          type="file"
          onChange={asyncEvent.execute}
        />
      </Box>
      <Box sx={{ mt: 1 }}>
        <Select
          labelId="select-organizer-label"
          id="select-organizer-label"
          value={selectOrganizerId}
          label="登録オーガナイザー"
          onChange={handleOrgnizerChange}
        >
          <MenuItem key="0" value="0">
            登録オーガナイザーを選択
          </MenuItem>
          {organizerlist.map((organizer) => (
            <MenuItem key={organizer.id} value={organizer.id}>
              {organizer.name}
            </MenuItem>
          ))}
        </Select>
      </Box>
      <Box sx={{ mt: 4 }}>
        <Button
          onClick={handleEdit}
          style={{ backgroundColor: "#FFA500" }}
          variant="contained"
        >
          {roleName}する
        </Button>
      </Box>
    </div>
  );
};

export default EventEdit;
