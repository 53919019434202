import { Button, TextField, Typography } from "@mui/material";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
} from "@mui/material";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { Box } from "@mui/system";
import { DataGrid, GridColDef, GridRowsProp } from "@mui/x-data-grid";
import React, { useRef, useState } from "react";
import { useAsyncCallback } from "react-async-hook";
import { useCookies } from "react-cookie";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "store/configureStore";

import noimage from "../../../assets/noimage.png";
import GameMasterDataAdminActionCell from "../../component/datagrid/GameAdminCharacterMasterDataGridAction";
import ImageCell from "../../component/datagrid/GridImage";
import CircularIntegration from "../../component/parts/CircularIntegration";
import { IMAGE_DOMAIN } from "../../const/constatns";
import { GameCharacter, GameMasterData } from "../../store/Admin/definition";
import {
  deletegamecharacters,
  deletegamedatamaster,
  editgamecharacters,
  editgamedatamaster,
  fetchGameData,
  getgamecharacters,
  getgamecharactersone,
  getgamedatamaster,
  getgamedatamasterone,
  registGameData,
} from "../../store/Admin/operations";
import { setState as setCommonState } from "../../store/Common/information";
import store from "../../store/configureStore";
import { CustomTabPanel } from "../common/CustomTabPanel";
import GameCharacterAdminActionCell from "../../component/datagrid/GameAdminCharacterGridAction";

interface Props {}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const GameEdit: React.FC<Props> = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [cookies, setCookie, removeCookie] = useCookies(["bs-token"]);
  const [roleName, seteRoleName] = useState("新規登録");
  const data: boolean = useSelector((state) => state.admin.gameOneData);
  const character_data: boolean = useSelector(
    (state) => state.admin.characterData
  );
  const game_master_data: boolean = useSelector(
    (state) => state.admin.gameMasterData
  );
  const game_master_one_data: GameMasterData = useSelector(
    (state) => state.admin.gameMasterOneData
  );
  const { id } = useParams();

  const [gameId, setGameId] = useState(null);
  const [uploadFileData, setUploadFileData] = useState(null);
  const [previewData, setPreviewData] = useState(null);
  const [gameName, setGameName] = useState("");
  const [gameCategory, setGameCategory] = useState("");

  const inputRef = useRef(null);
  const [success, setSuccess] = useState(false);
  const uploadFile = async (file) => {
    if (!file) return;
    setUploadFileData(file);
    setPreviewData(window.URL.createObjectURL(file));
    setSuccess(true);
  };
  const onFileInputChange = async (event) => {
    const file = event.target.files[0];
    await uploadFile(file);
  };
  const clickFileUploadButton = () => {
    setSuccess(false);
    inputRef.current.click();
  };
  const asyncEvent = useAsyncCallback(onFileInputChange);

  const c_inputRef = useRef(null);
  const [c_success, setCSuccess] = useState(false);
  const [characterUploadFileData, setCharacterUploadFileData] = useState(null);
  const [characterPreviewData, setCharacterPreviewData] = useState(null);
  const characterUploadFile = async (file) => {
    if (!file) return;
    setCharacterUploadFileData(file);
    setCharacterPreviewData(window.URL.createObjectURL(file));
    setCSuccess(true);
  };
  const onCharacterFileInputChange = async (event) => {
    const file = event.target.files[0];
    await characterUploadFile(file);
  };
  const clickCharacterFileUploadButton = () => {
    setCSuccess(false);
    c_inputRef.current.click();
  };
  const c_asyncEvent = useAsyncCallback(onCharacterFileInputChange);

  const handleEdit = async () => {
    const result = await dispatch<ReturnType<typeof registGameData>>(
      registGameData({
        payload: {
          id: id,
          name: gameName,
          category: gameCategory,
          file: uploadFileData,
        },
        access_token: cookies["bs-access-token"],
      })
    );
    if (result.payload["id"] != undefined) {
      store.dispatch(setCommonState({ alertDialogOpen: true }));
      store.dispatch(
        setCommonState({
          alertDialogMessage: `ゲームの${roleName}を行いました`,
        })
      );

      setGameId(result.payload["id"]);
      navigate(`/admin/game/edit/${result.payload["id"]}`, { replace: true });
    } else {
      store.dispatch(setCommonState({ alertDialogOpen: true }));
      store.dispatch(
        setCommonState({
          alertDialogMessage: `ゲームの${roleName}に失敗しました。`,
        })
      );
      store.dispatch(setCommonState({ alertDialogType: "error" }));
    }
  };
  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", width: 50 },
    {
      field: "character_imgpath",
      headerName: "キャラクター画像",
      width: 250,
      renderCell: (params) => <ImageCell value={params.value} />,
    },
    { field: "character_name", headerName: "キャラクター名", width: 250 },
    { field: "character_id", headerName: "CID", width: 100 },
    {
      field: "actions",
      headerName: "操作",
      width: 200,
      renderCell: (params) => (
        <GameCharacterAdminActionCell
          onEdit={() => handleCharacterDataEdit(params.row.id)}
          onClick={() => handleDeleteCharacterData(params.row.id)}
        />
      ),
    },
  ];
  const data_columns: GridColDef[] = [
    { field: "id", headerName: "ID", width: 50 },
    { field: "master_name", headerName: "データ名", width: 150 },
    { field: "master_display_name", headerName: "データ表示名", width: 350 },
    { field: "default_value", headerName: "デフォルト値", width: 100 },
    {
      field: "actions",
      headerName: "操作",
      width: 200,
      renderCell: (params) => (
        <GameMasterDataAdminActionCell
          onEdit={() => handleMasterDataIdEdit(params.row.id)}
          onClick={() => handleDeleteMasterData(params.row.id)}
        />
      ),
    },
  ];
  const [open, setOpen] = React.useState(false);
  const [characterMasterId, setCharacterMasterId] = React.useState(0);
  const [addCharacterName, setAddCharacterName] = React.useState("");
  const [addCharacterId, setAddCharacterId] = React.useState("");

  const handleClose = () => {
    setOpen(false);
  };
  const handleRegistcharacter = () => {
    setOpen(true);
  };
  const [masterOpen, setMasterOpen] = React.useState(false);
  const [masterId, setMasterId] = React.useState(0);
  const [masterName, setMasterName] = React.useState("");
  const [masterDisplayName, setMasterDisplayName] = React.useState("");
  const [masterDefault, setMasterDefault] = React.useState(0);

  const handleMasterClose = () => {
    setMasterOpen(false);
  };
  const handleRegistDataType = () => {
    setMasterName("");
    setMasterDisplayName("");
    setMasterDefault(0);
    setMasterOpen(true);
  };
  const handleEditMasterData = async () => {
    const result = await dispatch<ReturnType<typeof editgamedatamaster>>(
      editgamedatamaster({
        payload: {
          id: masterId,
          game_id: gameId,
          master_name: masterName,
          master_display_name: masterDisplayName,
          default_value: masterDefault,
        },
        access_token: cookies["bs-access-token"],
      })
    );
    if (result.payload["id"] != undefined) {
      store.dispatch(
        setCommonState({
          alertDialogOpen: true,
          alertDialogMessage: `データタイプを更新しました。`,
          alertDialogType: "success",
        })
      );
      store.dispatch(setCommonState({}));
    } else {
      store.dispatch(
        setCommonState({
          alertDialogOpen: true,
          alertDialogMessage: `データタイプの更新に失敗しました。`,
          alertDialogType: "error",
        })
      );
    }

    setMasterName("");
    setMasterId(0);
    setMasterDisplayName("");
    setMasterDefault(0);
    setMasterOpen(false);
    fetchGameMasterData();
  };
  const handleMasterDataIdEdit = async (id: number) => {
    const result: GameMasterData = await fetchGameMasterOneData(id);
    if (result != undefined) {
      setMasterName(result.master_name);
      setMasterDisplayName(result.master_display_name);
      setMasterDefault(result.default_value);
      setMasterId(id);
      setMasterOpen(true);
    }
  };
  const handleDeleteMasterData = async (id: number) => {
    store.dispatch(
      setCommonState({
        confirmDialogOpen: true,
        confirmDialogMessage: "データを削除してもよろしいですか？",
        confirmFunction: async () => {
          const result = await dispatch<
            ReturnType<typeof deletegamedatamaster>
          >(
            deletegamedatamaster({
              payload: {
                id: id,
              },
              access_token: cookies["bs-access-token"],
            })
          );

          if (result.error == undefined) {
            store.dispatch(
              setCommonState({
                alertDialogOpen: true,
                alertDialogType: "success",
                alertDialogMessage: "データを削除しました",
                confirmDialogOpen: false,
              })
            );
            fetchGameMasterData();
          }
        },
      })
    );
  };
  const handleEditCharacterData = async () => {
    const result = await dispatch<ReturnType<typeof editgamecharacters>>(
      editgamecharacters({
        payload: {
          id: characterMasterId,
          game_id: gameId,
          character_id: addCharacterId,
          character_name: addCharacterName,
          file: characterUploadFileData,
        },
        access_token: cookies["bs-access-token"],
      })
    );
    if (result.payload["id"] != undefined) {
      store.dispatch(
        setCommonState({
          alertDialogOpen: true,
          alertDialogMessage: `キャラクターデータを更新しました。`,
          alertDialogType: "success",
        })
      );
      store.dispatch(setCommonState({}));
    } else {
      store.dispatch(
        setCommonState({
          alertDialogOpen: true,
          alertDialogMessage: `キャラクターデータの更新に失敗しました。`,
          alertDialogType: "error",
        })
      );
    }

    setCharacterPreviewData(null);
    setAddCharacterId("");
    setAddCharacterName("");
    setCharacterUploadFileData(null);
    setCharacterMasterId(0)
    setOpen(false);
    fetchCharacterData();
  };
  const handleCharacterDataEdit = async (id: number) => {
    const result: GameCharacter = await fetchCharacterOneData(id);
    if (result != undefined) {
      setCharacterMasterId(id);
      setAddCharacterName(result.character_name);
      setAddCharacterId(result.character_id);
      setCharacterUploadFileData(null);
      setCharacterPreviewData(IMAGE_DOMAIN + result.character_imgpath);
      setOpen(true);
    }
  };
  const handleDeleteCharacterData = async (id: number) => {
    store.dispatch(
      setCommonState({
        confirmDialogOpen: true,
        confirmDialogMessage: "キャラクタデータを削除してもよろしいですか？",
        confirmFunction: async () => {
          const result = await dispatch<
            ReturnType<typeof deletegamecharacters>
          >(
            deletegamecharacters({
              payload: {
                id: id,
              },
              access_token: cookies["bs-access-token"],
            })
          );

          if (result.error == undefined) {
            store.dispatch(
              setCommonState({
                alertDialogOpen: true,
                alertDialogType: "success",
                alertDialogMessage: "キャラクタデータデータを削除しました",
                confirmDialogOpen: false,
              })
            );
            fetchCharacterData();
          }
        },
      })
    );
  };
  const fetchCharacterData = async () => {
    const resultCharacter = await dispatch<
      ReturnType<typeof getgamecharacters>
    >(
      getgamecharacters({
        payload: {
          id: gameId,
        },
        access_token: cookies["bs-access-token"],
      })
    );
  };
  const fetchCharacterOneData = async (id:number) => {
    const resultCharacter = await dispatch<
      ReturnType<typeof getgamecharactersone>
    >(
      getgamecharactersone({
        payload: {
          id: id,
          game_id: gameId,          
        },
        access_token: cookies["bs-access-token"],
      })
    );
    return resultCharacter?.payload;
  };
  const fetchGameMasterData = async () => {
    const result = await dispatch<ReturnType<typeof getgamedatamaster>>(
      getgamedatamaster({
        payload: {
          id: gameId,
        },
        access_token: cookies["bs-access-token"],
      })
    );
  };
  const fetchGameMasterOneData = async (id: number) => {
    const result = await dispatch<ReturnType<typeof getgamedatamasterone>>(
      getgamedatamasterone({
        payload: {
          id: id,
        },
        access_token: cookies["bs-access-token"],
      })
    );
    return result?.payload;
  };
  const fetchData = async () => {
    const result = await dispatch<ReturnType<typeof fetchGameData>>(
      fetchGameData({
        payload: {
          id: gameId,
        },
        access_token: cookies["bs-access-token"],
      })
    );
  };

  const [tabvalue, setTabValue] = React.useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  React.useEffect(() => {
    if (data != null) {
      setGameName(data.name);
      setGameCategory(data.category);
      if (data.imgpath != null) {
        setPreviewData(IMAGE_DOMAIN + data.imgpath);
      }
    }
  }, [data]);
  React.useEffect(() => {
    if (gameId != undefined) {
      fetchData();
      fetchCharacterData();
      fetchGameMasterData();
      seteRoleName("編集");
    } else {
      seteRoleName("新規登録");
    }
  }, [gameId]);
  React.useEffect(() => {
    setGameName("");
    setGameCategory("");

    if (id != undefined) setGameId(id);
  }, []);

  return (
    <div>
      <Breadcrumbs aria-label="breadcrumb">
        <Link to="/admin/game">ゲームデータ一覧</Link>
        <Link>{roleName}</Link>
      </Breadcrumbs>
      <h2>ゲームデータ{roleName}</h2>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          {gameId != undefined ? (
            <Typography variant="subtitle1">ID: {gameId}</Typography>
          ) : (
            ""
          )}
          <Box sx={{ mt: 1 }}>
            <TextField
              id="game_data_name"
              sx={{ width: 400 }}
              value={gameName}
              onChange={(event) => setGameName(event.target.value)}
              label="ゲーム名"
              variant="outlined"
            />
          </Box>
          <Box sx={{ mt: 1 }}>
            <TextField
              id="game_data_category"
              sx={{ width: 400 }}
              value={gameCategory}
              onChange={(event) => setGameCategory(event.target.value)}
              label="カテゴリ名"
              variant="outlined"
            />
          </Box>
          <Box sx={{ mt: 4, mb: 4 }}>
            <Button
              onClick={handleEdit}
              style={{ backgroundColor: "#FFA500" }}
              variant="contained"
            >
              {roleName}する
            </Button>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box sx={{ mt: 1 }}>
            <img
              height={150}
              src={previewData == null ? noimage : previewData}
            />
            <CircularIntegration
              onClick={clickFileUploadButton}
              asyncEvent={asyncEvent}
              success={success}
              component="label"
              text={asyncEvent.loading ? "..." : "サムネイル画像を選択"}
            />
            <input
              hidden
              ref={inputRef}
              type="file"
              onChange={asyncEvent.execute}
            />
          </Box>
        </Grid>
      </Grid>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={tabvalue}
          onChange={handleTabChange}
          aria-label="game_tabs"
        >
          <Tab label="キャラクター登録" {...a11yProps(0)} />
          <Tab label="データ登録" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={tabvalue} index={0}>
        <Box sx={{ mb: 1 }}>
          <Button
            onClick={handleRegistcharacter}
            sx={{ ml: 2 }}
            variant="contained"
          >
            新規登録
          </Button>
        </Box>
        <Box sx={{ backgroundColor: "white" }}>
          <DataGrid rowHeight={100} rows={character_data} columns={columns} />
        </Box>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>キャラクター情報登録</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="character_ID"
              label="キャラクターID"
              type="text"
              onChange={(e) => setAddCharacterId(e.target.value)}
              value={addCharacterId}
              variant="standard"
            />
            <br />
            <TextField
              margin="dense"
              id="character_name"
              label="キャラクター名"
              type="text"
              onChange={(e) => setAddCharacterName(e.target.value)}
              value={addCharacterName}
              variant="standard"
            />
            <br />
            <img
              height={100}
              src={
                characterPreviewData == null ? noimage : characterPreviewData
              }
            />
            <br />
            <CircularIntegration
              onClick={clickCharacterFileUploadButton}
              asyncEvent={c_asyncEvent}
              success={c_success}
              component="label"
              text={c_asyncEvent.loading ? "..." : "キャラクター画像を選択"}
            />
            <input
              hidden
              ref={c_inputRef}
              type="file"
              onChange={c_asyncEvent.execute}
            />
            ※キャラクター画像は1:1,600:600を推奨
            <br />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>キャンセル</Button>
            <Button onClick={handleEditCharacterData}>登録</Button>
          </DialogActions>
        </Dialog>
      </CustomTabPanel>
      <CustomTabPanel value={tabvalue} index={1}>
        <Box sx={{ mb: 1 }}>
          <Button
            onClick={handleRegistDataType}
            sx={{ ml: 2 }}
            variant="contained"
          >
            新規登録
          </Button>
        </Box>
        <Box sx={{ backgroundColor: "white" }}>
          <DataGrid
            rowHeight={100}
            rows={game_master_data}
            columns={data_columns}
          />
        </Box>
        <Dialog open={masterOpen} onClose={handleMasterClose}>
          <DialogTitle>データ情報登録</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="master_name"
              label="データ名(半角)"
              type="text"
              onChange={(e) => setMasterName(e.target.value)}
              value={masterName}
              variant="standard"
            />
            <br />
            <TextField
              margin="dense"
              id="master_display_name"
              label="データ表示名"
              type="text"
              onChange={(e) => setMasterDisplayName(e.target.value)}
              value={masterDisplayName}
              variant="standard"
            />
            <br />
            <TextField
              margin="dense"
              id="master_default"
              label="デフォルト値"
              type="number"
              onChange={(e) => setMasterDefault(e.target.value)}
              value={masterDefault}
              variant="standard"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleMasterClose}>キャンセル</Button>
            <Button onClick={handleEditMasterData}>登録</Button>
          </DialogActions>
        </Dialog>
      </CustomTabPanel>
    </div>
  );
};

export default GameEdit;
