import React from "react";

import noimage from "../../../assets/noimage.png";
import { IMAGE_DOMAIN } from "../../const/constatns";

const ImageCell = ({ value }) => (
  <img
    src={IMAGE_DOMAIN + value}
    alt="Image"
    height={100}
    onError={(e) => (e.target.src = noimage)}
  />
);

export default ImageCell;
