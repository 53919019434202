import { createAsyncThunk } from "@reduxjs/toolkit";

import { execute, Request } from "../../util/request";

const API_ENDPOINT = "/api/";
interface Args {
  payload: JSON;
  access_token: string;
}

export const registGameData = createAsyncThunk(
  "admin/editgame",
  async (args: Args) => {
    const request: Request = {
      endpoint: API_ENDPOINT + "editgame",
      method: "POST",
    };
    return await execute(
      request,
      "regist game failed",
      args.payload,
      args.access_token
    );
  }
);
export const deleteGameData = createAsyncThunk(
  "site/deletegamedata",
  async (args: Args) => {
    const request: Request = {
      endpoint: API_ENDPOINT + "deletegamedata",
      method: "POST",
    };
    return await execute(
      request,
      "deletegamedata failed",
      args.payload,
      args.access_token
    );
  }
);
export const fetchGameData = createAsyncThunk(
  "site/getgamedata",
  async (args: Args) => {
    const request: Request = {
      endpoint: API_ENDPOINT + "getgamedata",
      method: "POST",
    };
    return await execute(
      request,
      "getgamedata failed",
      args.payload,
      args.access_token
    );
  }
);
export const fetchGameList = createAsyncThunk(
  "site/getallgamedata",
  async (access_token: string) => {
    const request: Request = {
      endpoint: API_ENDPOINT + "getallgamedata",
      method: "GET",
    };
    return await execute(
      request,
      "getallgamedata failed",
      undefined,
      access_token
    );
  }
);
export const getgamecharacters = createAsyncThunk(
  "site/getgamecharacters",
  async (args: Args) => {
    const request: Request = {
      endpoint: API_ENDPOINT + "getgamecharacters",
      method: "POST",
    };
    return await execute(
      request,
      "getgamecharacters failed",
      args.payload,
      args.access_token
    );
  }
);
export const getgamecharactersone = createAsyncThunk(
  "site/getgamecharactersone",
  async (args: Args) => {
    const request: Request = {
      endpoint: API_ENDPOINT + "getgamecharactersone",
      method: "POST",
    };
    return await execute(
      request,
      "getgamecharactersone failed",
      args.payload,
      args.access_token
    );
  }
);
export const getgamedatamasterone = createAsyncThunk(
  "site/getgamedatamasterone",
  async (args: Args) => {
    const request: Request = {
      endpoint: API_ENDPOINT + "getgamedatamasterone",
      method: "POST",
    };
    return await execute(
      request,
      "getgamedatamasterone failed",
      args.payload,
      args.access_token
    );
  }
);
export const editgamecharacters = createAsyncThunk(
  "site/editgamecharacters",
  async (args: Args) => {
    const request: Request = {
      endpoint: API_ENDPOINT + "editgamecharacters",
      method: "POST",
    };
    return await execute(
      request,
      "editgamecharacters failed",
      args.payload,
      args.access_token
    );
  }
);
export const deletegamecharacters = createAsyncThunk(
  "site/deletegamecharacters",
  async (args: Args) => {
    const request: Request = {
      endpoint: API_ENDPOINT + "deletegamecharacters",
      method: "POST",
    };
    return await execute(
      request,
      "deletegamecharacters failed",
      args.payload,
      args.access_token
    );
  }
);
export const getgamedatamaster = createAsyncThunk(
  "site/getgamedatamaster",
  async (args: Args) => {
    const request: Request = {
      endpoint: API_ENDPOINT + "getgamedatamaster",
      method: "POST",
    };
    return await execute(
      request,
      "getgamedatamaster failed",
      args.payload,
      args.access_token
    );
  }
);
export const editgamedatamaster = createAsyncThunk(
  "site/editgamedatamaster",
  async (args: Args) => {
    const request: Request = {
      endpoint: API_ENDPOINT + "editgamedatamaster",
      method: "POST",
    };
    return await execute(
      request,
      "editgamedatamaster failed",
      args.payload,
      args.access_token
    );
  }
);
export const deletegamedatamaster = createAsyncThunk(
  "site/deletegamedatamaster",
  async (args: Args) => {
    const request: Request = {
      endpoint: API_ENDPOINT + "deletegamedatamaster",
      method: "POST",
    };
    return await execute(
      request,
      "deletegamedatamaster failed",
      args.payload,
      args.access_token
    );
  }
);
export const fetchUserList = createAsyncThunk(
  "user/list",
  async (access_token: string) => {
    const request: Request = {
      endpoint: API_ENDPOINT + "user/list",
      method: "get",
    };
    return await execute(
      request,
      "fetchUserList failed",
      undefined,
      access_token
    );
  }
);
export const deleteUser = createAsyncThunk(
  "user/delete",
  async (args: Args) => {
    const request: Request = {
      endpoint: API_ENDPOINT + "user/delete",
      method: "POST",
    };
    return await execute(
      request,
      "deleteUser failed",
      args.payload,
      args.access_token
    );
  }
);
