import { yupResolver } from "@hookform/resolvers/yup";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import React from "react";
import { useCookies } from "react-cookie";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { Link, redirect } from "react-router-dom";
import { useSelector } from "store/configureStore";

import { setState } from "../../store/Login/information";
import { fetchLogin } from "../../store/Login/operations";
import { LoginValidateScheme } from "../../util/validation/LoginValidate";

interface Props {}

const Login: React.FC<Props> = () => {
  const dispatch = useDispatch();
  const loading: boolean = useSelector((state) => state.information.loading);
  const [cookies, setCookie, removeCookie] = useCookies(["bs-token"]);

  const {
    register,
    handleSubmit,
    formState: { isDirty, errors },
  } = useForm({
    mode: "onChange",
    criteriaMode: "all",
    resolver: yupResolver(LoginValidateScheme),
  });
  const onSubmit = async (data: any) => {
    if (!loading) {
      const result = await dispatch<ReturnType<typeof fetchLogin>>(
        fetchLogin({
          payload: {
            email: data.email,
            password: data.password,
          },
        })
      );
      if (result.error === undefined) {
        // ログイン成功
        setCookie("bs-access-token", result.payload.access_token, {
          path: "/",
        });
        setCookie("bs-type", result.payload.token_type, { path: "/" });
        dispatch(setState({ isLoggedIn: true }));
        location.reload();
      } else {
        // ログイン失敗
        console.error(result.error.message);
      }
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 4, bgcolor: "secondary.main" }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          BattleScore管理サイト ログイン
        </Typography>
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          noValidate
          sx={{ width: 400, mt: 2 }}
        >
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email"
            name="email"
            autoComplete="email"
            autoFocus
            {...register("email")}
          />
          <Typography component="p" style={{ color: "red" }}>
            {errors.email?.message}
          </Typography>
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            {...register("password")}
          />
          <Typography component="p" style={{ color: "red" }}>
            {errors.password?.message}
          </Typography>
          <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="次回から入力を省略"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            disabled={!isDirty || Object.keys(errors).length > 0}
            sx={{ mt: 3, mb: 2 }}
          >
            ログイン
          </Button>
          <Grid container>
            <Grid item xs>
              <Link href="#" to="/create" variant="body2">
                ユーザー登録
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
};

export default Login;
