import AnalyticsIcon from "@mui/icons-material/Analytics";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import PeopleIcon from "@mui/icons-material/People";
import Tooltip from "@mui/material/Tooltip";
import React from "react";

const ActionCell = ({
  onRegist,
  onPlayerRegist,
  onEdit,
  onClick,
  onAnalytics,
}) => (
  <div>
    <Tooltip title="プレイヤー登録">
      <PeopleIcon sx={{ cursor: "pointer" }} onClick={onPlayerRegist} />
    </Tooltip>
    <Tooltip title="試合一覧">
      <AppRegistrationIcon sx={{ cursor: "pointer" }} onClick={onRegist} />
    </Tooltip>
    <Tooltip title="解析">
      <AnalyticsIcon sx={{ cursor: "pointer" }} onClick={onAnalytics} />
    </Tooltip>
    <Tooltip title="編集">
      <EditIcon sx={{ cursor: "pointer" }} onClick={onEdit} />
    </Tooltip>
    {onClick!=undefined ? (
    <Tooltip title="削除">
      <DeleteIcon sx={{ cursor: "pointer" }} onClick={onClick} />
    </Tooltip>
    ) : ""}
  </div>
);

export default ActionCell;
