import { createAsyncThunk } from "@reduxjs/toolkit";

import { execute, Request } from "../../util/request";

const API_ENDPOINT = "/api/";
interface Args {
  payload: JSON;
  access_token: string;
}

export const fetchAnalyticsTournament = createAsyncThunk(
  "site/analytics/analyzetournamentscore",
  async (args: Args) => {
    const request: Request = {
      endpoint: API_ENDPOINT + "analyzetournamentscore",
      method: "POST",
    };
    return await execute(
      request,
      "analyzetournamentscore failed",
      args.payload,
      args.access_token
    );
  }
);
export const getarticle = createAsyncThunk(
  "site/analytics/getarticle",
  async (args: Args) => {
    const request: Request = {
      endpoint: API_ENDPOINT + "getarticle",
      method: "POST",
    };
    return await execute(
      request,
      "getarticle failed",
      args.payload,
      args.access_token
    );
  }
);
export const editarticle = createAsyncThunk(
  "site/analytics/editarticle",
  async (args: Args) => {
    const request: Request = {
      endpoint: API_ENDPOINT + "editarticle",
      method: "POST",
    };
    return await execute(
      request,
      "editarticle failed",
      args.payload,
      args.access_token
    );
  }
);
export const makebattlescorearticle = createAsyncThunk(
  "site/analytics/makebattlescorearticle",
  async (args: Args) => {
    const request: Request = {
      endpoint: API_ENDPOINT + "makebattlescorearticle",
      method: "POST",
    };
    return await execute(
      request,
      "makebattlescorearticle failed",
      args.payload,
      args.access_token
    );
  }
);
export const gettournamentranking = createAsyncThunk(
  "site/analytics/gettournamentranking",
  async (args: Args) => {
    const request: Request = {
      endpoint: API_ENDPOINT + "gettournamentranking",
      method: "POST",
    };
    return await execute(
      request,
      "gettournamentranking failed",
      args.payload,
      args.access_token
    );
  }
);
export const gettournamentplayerranking = createAsyncThunk(
  "site/analytics/gettournamentplayerranking",
  async (args: Args) => {
    const request: Request = {
      endpoint: API_ENDPOINT + "gettournamentplayerranking",
      method: "POST",
    };
    return await execute(
      request,
      "gettournamentplayerranking failed",
      args.payload,
      args.access_token
    );
  }
);
export const gettournamentcharacterranking = createAsyncThunk(
  "site/analytics/gettournamentcharacterranking",
  async (args: Args) => {
    const request: Request = {
      endpoint: API_ENDPOINT + "gettournamentcharacterranking",
      method: "POST",
    };
    return await execute(
      request,
      "gettournamentcharacterranking failed",
      args.payload,
      args.access_token
    );
  }
);
export const gettournamentmatchlist = createAsyncThunk(
  "site/analytics/gettournamentmatchlist",
  async (args: Args) => {
    const request: Request = {
      endpoint: API_ENDPOINT + "gettournamentmatchlist",
      method: "POST",
    };
    return await execute(
      request,
      "gettournamentmatchlist failed",
      args.payload,
      args.access_token
    );
  }
);
export const geteventranking = createAsyncThunk(
  "site/analytics/geteventranking",
  async (args: Args) => {
    const request: Request = {
      endpoint: API_ENDPOINT + "geteventranking",
      method: "POST",
    };
    return await execute(
      request,
      "geteventranking failed",
      args.payload,
      args.access_token
    );
  }
);
export const geteventplayerranking = createAsyncThunk(
  "site/analytics/geteventplayerranking",
  async (args: Args) => {
    const request: Request = {
      endpoint: API_ENDPOINT + "geteventplayerranking",
      method: "POST",
    };
    return await execute(
      request,
      "geteventplayerranking failed",
      args.payload,
      args.access_token
    );
  }
);
export const geteventcharacterranking = createAsyncThunk(
  "site/analytics/geteventcharacterranking",
  async (args: Args) => {
    const request: Request = {
      endpoint: API_ENDPOINT + "geteventcharacterranking",
      method: "POST",
    };
    return await execute(
      request,
      "geteventcharacterranking failed",
      args.payload,
      args.access_token
    );
  }
);
export const getgameranking = createAsyncThunk(
  "site/analytics/getgameranking",
  async (args: Args) => {
    const request: Request = {
      endpoint: API_ENDPOINT + "getgameranking",
      method: "POST",
    };
    return await execute(
      request,
      "getgameranking failed",
      args.payload,
      args.access_token
    );
  }
);
export const getgamecharacterplayranking = createAsyncThunk(
  "site/analytics/getgamecharacterplayranking",
  async (args: Args) => {
    const request: Request = {
      endpoint: API_ENDPOINT + "getgamecharacterplayranking",
      method: "POST",
    };
    return await execute(
      request,
      "getgamecharacterplayranking failed",
      args.payload,
      args.access_token
    );
  }
);
export const getgamecharacterplayingranking = createAsyncThunk(
  "site/analytics/getgamecharacterplayingranking",
  async (args: Args) => {
    const request: Request = {
      endpoint: API_ENDPOINT + "getgamecharacterplayingranking",
      method: "POST",
    };
    return await execute(
      request,
      "getgamecharacterplayingranking failed",
      args.payload,
      args.access_token
    );
  }
);
export const getplayertotalanalytics = createAsyncThunk(
  "site/analytics/getplayertotalanalytics",
  async (args: Args) => {
    const request: Request = {
      endpoint: API_ENDPOINT + "getplayertotalanalytics",
      method: "POST",
    };
    return await execute(
      request,
      "getplayertotalanalytics failed",
      args.payload,
      args.access_token
    );
  }
);
export const getplayertournamentscoreanalytics = createAsyncThunk(
  "site/analytics/getplayertournamentscoreanalytics",
  async (args: Args) => {
    const request: Request = {
      endpoint: API_ENDPOINT + "getplayertournamentscoreanalytics",
      method: "POST",
    };
    return await execute(
      request,
      "getplayertournamentscoreanalytics failed",
      args.payload,
      args.access_token
    );
  }
);
export const getplayerpersonalanalytics = createAsyncThunk(
  "site/analytics/getplayerpersonalanalytics",
  async (args: Args) => {
    const request: Request = {
      endpoint: API_ENDPOINT + "getplayerpersonalanalytics",
      method: "POST",
    };
    return await execute(
      request,
      "getplayerpersonalanalytics failed",
      args.payload,
      args.access_token
    );
  }
);