import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import merge from "lodash/merge";

import {
  EventData,
  MatchData,
  PlayerData,
  PlayerDataWithDetail,
  PlayerDetailData,
  tournamentListData,
  TournamentOneData,
} from "./definition";
import {
  fetchAllEventList,
  fetchEventData,
  fetchPlayerInfoDatas,
  fetchTournamentData,
  fetchTournamentList,
  getmatchdata,
  getmatchdatas,
  getPlayerData,
  getPlayerList,
} from "./operations";

type State = {
  tournamentData: Array<tournamentListData>;
  playerlistData: Array<PlayerDataWithDetail>;
  playerDetailData: PlayerDataWithDetail | null;
  eventData: Array<EventData>;
  eventOneData: EventData | null;
  playerData: Array<PlayerData>;
  matchData: Array<MatchData>;
  matchOneData: MatchData | null;
  tournamentOneData: TournamentOneData | null;  
  status: string;
  error: string;
};

const initialState: State = {
  tournamentData: [],
  playerlistData: [],
  playerDetailData: null,
  eventData: [],
  eventOneData: null,
  playerData: [],
  matchData: [],
  matchOneData: null,
  tournamentOneData: null,
  status: "",
  error: "",
};

const slice = createSlice({
  name: "site",
  initialState,
  reducers: {
    initializeState: (state) => {
      return Object.assign({}, initialState);
    },
    setState: (state, action: PayloadAction<Partial<State>>) => {
      Object.keys(action.payload).map((key) => {
        merge(state, { [key]: action.payload[key] });
      });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchTournamentList.pending, (state) => {
      state.status = "pending";
    });
    builder.addCase(fetchTournamentList.fulfilled, (state, action) => {
      state.tournamentData = action.payload;
      state.status = "succeeded";
    });
    builder.addCase(fetchAllEventList.pending, (state) => {
      state.status = "pending";
    });
    builder.addCase(fetchAllEventList.fulfilled, (state, action) => {
      state.eventData = action.payload;
      state.status = "succeeded";
    });
    builder.addCase(fetchEventData.pending, (state) => {
      state.status = "pending";
    });
    builder.addCase(fetchEventData.fulfilled, (state, action) => {
      state.eventOneData = action.payload;
      state.status = "succeeded";
    });
    builder.addCase(fetchTournamentData.pending, (state) => {
      state.status = "pending";
    });
    builder.addCase(fetchTournamentData.fulfilled, (state, action) => {
      state.tournamentOneData = action.payload;
      state.status = "succeeded";
    });
    builder.addCase(fetchPlayerInfoDatas.pending, (state) => {
      state.status = "pending";
    });
    builder.addCase(fetchPlayerInfoDatas.fulfilled, (state, action) => {
      state.playerData = action.payload;
      state.status = "succeeded";
    });
    builder.addCase(getmatchdatas.pending, (state) => {
      state.status = "pending";
    });
    builder.addCase(getmatchdatas.fulfilled, (state, action) => {
      state.matchData = action.payload;
      state.status = "succeeded";
    });
    builder.addCase(getmatchdata.pending, (state) => {
      state.status = "pending";
    });
    builder.addCase(getmatchdata.fulfilled, (state, action) => {
      state.matchOneData = action.payload;
      state.status = "succeeded";
    });
    builder.addCase(getPlayerList.fulfilled, (state, action) => {
      state.playerlistData = action.payload;
      state.status = "succeeded";
    });
    builder.addCase(getPlayerData.fulfilled, (state, action) => {
      state.playerDetailData = action.payload;
      state.status = "succeeded";
    });
  },
});

export default slice.reducer;

export const { initializeState, setState } = slice.actions;
