import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { Breadcrumbs, Button, TextField } from "@mui/material/";
import { Box } from "@mui/system";
import { DataGrid, GridColDef, GridRowsProp } from "@mui/x-data-grid";
import React, { useRef, useState } from "react";
import { useAsyncCallback } from "react-async-hook";
import { useCookies } from "react-cookie";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useSelector } from "store/configureStore";

import PlayerSiteGridActionCell from "../../component/datagrid/PlayerSiteGridAction";
import CircularIntegration from "../../component/parts/CircularIntegration";
import { setState as setCommonState } from "../../store/Common/information";
import store from "../../store/configureStore";
import {
  deletePlayerInfo,
  deleteTournamentData,
  fetchPlayerInfoDatas,
  fetchTournamentList,
  registPlayerInfo,
  updateplayerrank,
  uploadPlayerCSV,
} from "../../store/Site/operations";

interface Props { }

const PlayerRegist: React.FC<Props> = () => {
  const dispatch = useDispatch();
  const [cookies, setCookie, removeCookie] = useCookies(["bs-token"]);
  const data: boolean = useSelector((state) => state.site.playerData);
  const { tid } = useParams();
  const navigate = useNavigate();

  const inputRef = useRef(null);
  const [success, setSuccess] = useState(false);
  const [uplodData, setUploadData] = useState(null);

  const uploadFile = async (file) => {
    if (!file) return;
    //connect fileupload
    setSuccess(true);
    setUploadData(file);
  };

  const onFileInputChange = async (event) => {
    const file = event.target.files[0];
    await uploadFile(file);
  };

  const clickFileUploadButton = () => {
    setSuccess(false);
    inputRef.current.click();
  };
  const asyncEvent = useAsyncCallback(onFileInputChange);

  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", width: 50 },
    { field: "custom_id", headerName: "プレイヤーID", width: 150 },
    { field: "name", headerName: "プレイヤー名", width: 200 },
    { field: "join_order", headerName: "登録順", width: 100 },
    {
      field: "winpoint", headerName: "順位", width: 100,
      renderCell: (params) => (
        <TextField
          value={params.row.winpoint}
          onChange={(event) => updatePlayerWinPoint(params.row.id,event.target.value)}
          sx={{ width: 100 }}
          id="player1_win"
          type="number"
          label="順位"
          variant="standard"
        />
      ),
    },
    {
      field: "actions",
      headerName: "操作",
      width: 200,
      renderCell: (params) => (
        <PlayerSiteGridActionCell onClick={() => handleDelete(params.row.id)} />
      ),
    },
  ];
  const updatePlayerWinPoint = async (id,winpoint) => {
    const result = await dispatch<ReturnType<typeof updateplayerrank>>(
      updateplayerrank({
        payload: {
          id: id,
          winpoint: winpoint,
        },
        access_token: cookies["bs-access-token"],
      })
    );
  }
  const fetchData = async () => {
    const result = await dispatch<ReturnType<typeof fetchPlayerInfoDatas>>(
      fetchPlayerInfoDatas({
        payload: {
          tournament_id: tid,
        },
        access_token: cookies["bs-access-token"],
      })
    );
  };

  const handleRegistPlayerCSV = async () => {
    if (uplodData != undefined) {
      const result = await dispatch<ReturnType<typeof uploadPlayerCSV>>(
        uploadPlayerCSV({
          payload: {
            tournament_id: tid,
            file: uplodData,
          },
          access_token: cookies["bs-access-token"],
        })
      );
      if (result.error == undefined) {
        store.dispatch(
          setCommonState({
            alertDialogOpen: true,
            alertDialogMessage: "プレイヤー登録が完了しました",
            alertDialogType: "success",
          })
        );
        fetchData();
      } else {
        store.dispatch(
          setCommonState({
            alertDialogOpen: true,
            alertDialogMessage: "プレイヤー登録に失敗しました",
            alertDialogType: "error",
          })
        );
      }
    } else {
      store.dispatch(
        setCommonState({
          alertDialogOpen: true,
          alertDialogMessage: "ファイルが選択されていません",
          alertDialogType: "error",
        })
      );
    }
  };

  const handleDelete = async (id) => {
    const result = await dispatch<ReturnType<typeof deletePlayerInfo>>(
      deletePlayerInfo({
        payload: {
          id: id,
        },
        access_token: cookies["bs-access-token"],
      })
    );
    fetchData();
  };

  const [open, setOpen] = React.useState(false);
  const [newplayername, setNewPlayerName] = React.useState("");
  const [newplayerid, setNewPlayerId] = React.useState("");
  const [newJoinOrder, setNewJoinOrder] = React.useState(0);

  const handleCreate = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleAddPlayerdata = async () => {
    const result = await dispatch<ReturnType<typeof registPlayerInfo>>(
      registPlayerInfo({
        payload: {
          tournament_id: tid,
          name: newplayername,
          custom_id: newplayerid,
          join_order: newJoinOrder,
        },
        access_token: cookies["bs-access-token"],
      })
    );
    setOpen(false);
    fetchData();
  };

  React.useEffect(() => {
    fetchData();
    setUploadData(null);
  }, []);

  return (
    <div>
      <Breadcrumbs aria-label="breadcrumb">
        <Link to="/site/tournament">トーナメント一覧</Link>
        <Link>プレイヤー管理</Link>
      </Breadcrumbs>
      <h2>プレイヤー管理</h2>
      <Box sx={{ width: 600 }}>
        <CircularIntegration
          onClick={clickFileUploadButton}
          asyncEvent={asyncEvent}
          success={success}
          component="label"
          text={asyncEvent.loading ? "..." : "プレイヤー登録CSVを選択"}
        />
        <input
          hidden
          ref={inputRef}
          type="file"
          onChange={asyncEvent.execute}
        />
        <Button
          onClick={handleRegistPlayerCSV}
          sx={{ mt: 1, mb: 2 }}
          variant="contained"
        >
          CSVアップロード
        </Button>
      </Box>
      <h2>
        登録プレイヤー一覧
        <Button onClick={handleCreate} sx={{ ml: 2 }} variant="contained">
          新規登録
        </Button>
      </h2>
      <Box sx={{ backgroundColor: "white" }}>
        <DataGrid rowHeight={100} rows={data} columns={columns} />
      </Box>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>プレイヤー登録</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="custom_id"
            label="プレイヤーID"
            type="text"
            onChange={(e) => setNewPlayerId(e.target.value)}
            value={newplayerid}
            variant="standard"
          />
          <br />
          <TextField
            margin="dense"
            id="player_name"
            label="プレイヤー名"
            type="text"
            onChange={(e) => setNewPlayerName(e.target.value)}
            value={newplayername}
            variant="standard"
          />
          <br />
          <TextField
            margin="dense"
            id="order_no"
            label="登録順"
            type="number"
            onChange={(e) => setNewJoinOrder(Number(e.target.value))}
            value={newJoinOrder}
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>キャンセル</Button>
          <Button onClick={handleAddPlayerdata}>登録</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default PlayerRegist;
