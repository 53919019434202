import { Box } from "@mui/system";
import { DataGrid, GridColDef, GridRowsProp } from "@mui/x-data-grid";
import React from "react";
import { useCookies } from "react-cookie";
import { useDispatch } from "react-redux";
import { useSelector } from "store/configureStore";

import ActionCell from "../../component/datagrid/GridAction";
import ImageCell from "../../component/datagrid/GridImage";
import { fetchTournamentList } from "../../store/Site/operations";

interface Props {}

const Dashboard: React.FC<Props> = () => {
  const dispatch = useDispatch();
  const [cookies, setCookie, removeCookie] = useCookies(["bs-token"]);
  const data: boolean = useSelector((state) => state.site.tournamentData);

  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", width: 50 },
    {
      field: "imgpath",
      headerName: "イベント画像",
      width: 250,
      renderCell: (params) => <ImageCell value={params.value} />,
    },
    { field: "name", headerName: "トーナメント名", width: 250 },
    {
      field: "event",
      headerName: "イベント名",
      width: 250,
      renderCell: (params) => {
        return <div className="rowitem">{params.row.event.eventname}</div>;
      },
    },
    {
      field: "event.game.name",
      headerName: "ゲーム",
      width: 200,
      renderCell: (params) => {
        return <div className="rowitem">{params.row.event.game.name}</div>;
      },
    },
    {
      field: "actions",
      headerName: "操作",
      width: 200,
      renderCell: (params) => (
        <ActionCell
          onRegist={() => handleRegist(params.row.id)}
          onEdit={() => handleEdit(params.row.id)}
          onClick={() => handleDelete(params.row.id)}
        />
      ),
    },
  ];

  const rows: GridRowsProp = [
    {
      id: 1,
      event_image: "John",
      event_name: "Doe",
      game: 25,
      operation: "john.doe@example.com",
    },
    {
      id: 2,
      event_image: "Jane",
      event_name: "Smith",
      game: 32,
      operation: "jane.smith@example.com",
    },
  ];

  const handleRegist = (id: number) => {
    //console.log(id);
  };
  const handleEdit = (id: number) => {
    //console.log(id);
  };
  const handleDelete = (id: number) => {
    //console.log(id);
  };

  const fetchData = async () => {
    const result = await dispatch<ReturnType<typeof fetchTournamentList>>(
      fetchTournamentList(cookies["bs-access-token"])
    );
  };
  React.useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      <h2>管理トーナメント一覧</h2>
      <Box sx={{ backgroundColor: "white" }}>
        <DataGrid rowHeight={100} rows={data} columns={columns} />
      </Box>
    </div>
  );
};

export default Dashboard;
