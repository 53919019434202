import { yupResolver } from "@hookform/resolvers/yup";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { Breadcrumbs, Button, TextField, Typography } from "@mui/material/";
import { Box } from "@mui/system";
import { DataGrid, GridColDef, GridRowsProp } from "@mui/x-data-grid";
import React from "react";
import { useCookies } from "react-cookie";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useSelector } from "store/configureStore";

import ActionCell from "../../component/datagrid/GridAction";
import ImageCell from "../../component/datagrid/GridImage";
import UserActionCell from "../../component/datagrid/UserAction";
import { UserData } from "../../store/Admin/definition";
import { deleteUser, fetchUserList } from "../../store/Admin/operations";
import { setState as setCommonState } from "../../store/Common/information";
import store from "../../store/configureStore";
import { fetchRegist } from "../../store/Login/operations";
import {
  deleteTournamentData,
  fetchTournamentList,
} from "../../store/Site/operations";
import { LoginScheme } from "../../util/validation/CreateUserValidate";

interface Props {}

const UserList: React.FC<Props> = () => {
  const dispatch = useDispatch();
  const [cookies, setCookie, removeCookie] = useCookies(["bs-token"]);
  const data: Array<UserData> = useSelector((state) => state.admin.userData);

  const {
    register,
    handleSubmit,
    formState: { isDirty, errors },
  } = useForm({
    mode: "onChange",
    criteriaMode: "all",
    resolver: yupResolver(LoginScheme),
  });
  const [samplePassword, setSamplePassword] = React.useState<string>("");

  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);
  const handleCreate = () => {
    setOpen(true);
    setSamplePassword(generatePassword());
  };
  const onSubmit = async (data: any) => {
    const result = await dispatch<ReturnType<typeof fetchRegist>>(
      fetchRegist({
        payload: {
          name: data.name,
          email: data.email,
          password: data.password,
        },
      })
    );
    if (result.error == undefined) {
      store.dispatch(
        setCommonState({
          alertDialogOpen: true,
          alertDialogType: "success",
          alertDialogMessage: "ユーザーを登録しました",
          confirmDialogOpen: false,
        })
      );
      setOpen(false);
      fetchData();
    }
  };
  const generatePassword = (): string => {
    const length = 12;
    const charset =
      "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_+~`|}{[]:;?><,./-=";
    let password = "";
    for (let i = 0, n = charset.length; i < length; ++i) {
      password += charset.charAt(Math.floor(Math.random() * n));
    }
    return password;
  };

  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", width: 50 },
    { field: "name", headerName: "ユーザー名", width: 250 },
    { field: "email", headerName: "メールアドレス", width: 250 },
    {
      field: "actions",
      headerName: "操作",
      width: 200,
      renderCell: (params) => (
        <UserActionCell onClick={() => handleDelete(params.row?.id)} />
      ),
    },
  ];

  const handleDelete = (id: number) => {
    store.dispatch(
      setCommonState({
        confirmDialogOpen: true,
        confirmDialogMessage: "ユーザーを削除してもよろしいですか？",
        confirmFunction: async () => {
          const result = await dispatch<ReturnType<typeof deleteUser>>(
            deleteUser({
              payload: {
                id: id,
              },
              access_token: cookies["bs-access-token"],
            })
          );
          if (result.error == undefined) {
            store.dispatch(
              setCommonState({
                alertDialogOpen: true,
                alertDialogType: "success",
                alertDialogMessage: "データを削除しました",
                confirmDialogOpen: false,
              })
            );
            fetchData();
          }
        },
      })
    );
  };

  const fetchData = async () => {
    const result = await dispatch<ReturnType<typeof fetchUserList>>(
      fetchUserList(cookies["bs-access-token"])
    );
  };
  React.useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      <Breadcrumbs aria-label="breadcrumb">
        <Link>ユーザー一覧</Link>
      </Breadcrumbs>
      <h2>
        管理ユーザー一覧
        <Button onClick={handleCreate} sx={{ ml: 2 }} variant="contained">
          新規登録
        </Button>
      </h2>
      <Box sx={{ backgroundColor: "white" }}>
        <DataGrid rowHeight={100} rows={data} columns={columns} />
      </Box>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>ユーザー登録</DialogTitle>
        <DialogContent>
          <DialogContentText>新規ユーザー情報を設定</DialogContentText>
          <Box
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            noValidate
            sx={{ width: 400, mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="name"
              label="Name"
              name="name"
              autoComplete="name"
              autoFocus
              {...register("name")}
            />
            <Typography component="p" style={{ color: "red" }}>
              {errors.name?.message}
            </Typography>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              {...register("email")}
            />
            <Typography component="p" style={{ color: "red" }}>
              {errors.email?.message}
            </Typography>
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              {...register("password")}
            />
            サンプルパスワード：{samplePassword}
            <Typography component="p" style={{ color: "red" }}>
              {errors.password?.message}
            </Typography>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              disabled={!isDirty || Object.keys(errors).length > 0}
              sx={{ mt: 3, mb: 2 }}
            >
              ユーザー登録
            </Button>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>キャンセル</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default UserList;
