import { Breadcrumbs, Button } from "@mui/material/";
import { Box } from "@mui/system";
import { DataGrid, GridColDef, GridRowsProp } from "@mui/x-data-grid";
import React from "react";
import { useCookies } from "react-cookie";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { useSelector } from "store/configureStore";

import GameAdminActionCell from "../../component/datagrid/GameAdminGridAction";
import ImageCell from "../../component/datagrid/GridImage";
import { fetchGameList } from "../../store/Admin/operations";
import { setState as setCommonState } from "../../store/Common/information";
import store from "../../store/configureStore";
import { fetchOrganizer, fetchUser } from "../../store/Login/operations";
import { EventData } from "../../store/Site/definition";
import {
  deleteEventData,
  fetchAllEventList,
} from "../../store/Site/operations";

interface Props {}

const Event: React.FC<Props> = () => {
  const dispatch = useDispatch();
  const [cookies, setCookie, removeCookie] = useCookies(["bs-token"]);
  const data: Array<EventData> = useSelector((state) => state.site.eventData);
  const gamelist: boolean = useSelector((state) => state.admin.gameData);

  const navigate = useNavigate();

  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", width: 50 },
    {
      field: "imgpath",
      headerName: "イベント画像",
      width: 250,
      renderCell: (params) => <ImageCell value={params.value} />,
    },
    { field: "eventname", headerName: "イベント名", width: 250 },
    {
      field: "game",
      headerName: "ゲーム",
      width: 200,
      renderCell: (params) => {
        const game = gamelist.find((item) => item.id === params.row.game_id);
        return game?.name;
      },
    },
    {
      field: "actions",
      headerName: "操作",
      width: 200,
      renderCell: (params) => (
        <GameAdminActionCell
          onEdit={() => handleEdit(params.row.id)}
          onAnalytics={() => handleAnalytics(params.row.id)}
          onClick={() => handleDelete(params.row.id)}
        />
      ),
    },
  ];

  const handleEdit = (id: number) => {
    navigate(`/site/event/edit/${id}`);
  };
  const handleAnalytics = (id: number) => {
    navigate(`/site/common/analytics/event/${id}`);
  };
  const handleCreate = () => {
    navigate(`/site/event/edit/`);
  };
  const handleDelete = async (id: number) => {
    store.dispatch(
      setCommonState({
        confirmDialogOpen: true,
        confirmDialogMessage:
          "データを削除してもよろしいですか？(関連トーナメント情報は削除されません)",
        confirmFunction: async () => {
          const result = await dispatch<ReturnType<typeof deleteEventData>>(
            deleteEventData({
              payload: {
                id: id,
              },
              access_token: cookies["bs-access-token"],
            })
          );
          if (result.error == undefined) {
            store.dispatch(
              setCommonState({
                alertDialogOpen: true,
                alertDialogType: "success",
                alertDialogMessage: "データを削除しました",
                confirmDialogOpen: false,
              })
            );
            fetchData();
          }
        },
      })
    );
  };
  const fetchGameListData = async () => {
    const result = await dispatch<ReturnType<typeof fetchGameList>>(
      fetchGameList(cookies["bs-access-token"])
    );
  };
  const fetchData = async () => {
    const result = await dispatch<ReturnType<typeof fetchAllEventList>>(
      fetchAllEventList({
        payload: {},
        access_token: cookies["bs-access-token"],
      })
    );
  };
  React.useEffect(() => {
    fetchData();
    fetchGameListData();
  }, []);

  return (
    <div>
      <Breadcrumbs aria-label="breadcrumb">
        <Link>イベント一覧</Link>
      </Breadcrumbs>
      <h2>
        イベントデータ一覧
        <Button onClick={handleCreate} sx={{ ml: 2 }} variant="contained">
          新規登録
        </Button>
      </h2>
      <Box sx={{ backgroundColor: "white" }}>
        <DataGrid rowHeight={100} rows={data} columns={columns} />
      </Box>
    </div>
  );
};

export default Event;
