import React from "react";
import styled, { css } from "styled-components";

import {
  geteventcharacterranking,
  geteventplayerranking,
  geteventranking,
  getgamecharacterplayingranking,
  getgamecharacterplayranking,
  getgameranking,
  getplayerpersonalanalytics,
  getplayertotalanalytics,
  getplayertournamentscoreanalytics,
  gettournamentcharacterranking,
  gettournamentmatchlist,
  gettournamentplayerranking,
  gettournamentranking,
} from "../store/Analytics/operations";
import { useSelector } from "../store/configureStore";

export const selectAnalyticsTypeData = (type) => {
  switch (type) {
    case "game":
      return [
        { id: "default", name: "-データタイプを選択-" },
        { id: "game_character_ranking", name: "ゲーム勝ち数ランキング" },
        {
          id: "game_character_point_ranking",
          name: "ゲームプレイヤー詳細ランキング",
        },
        {
          id: "game_player_total_ranking",
          name: "ゲームキャラクタープレイヤー使用ランキング",
        },
      ];
    case "event":
      return [
        { id: "default", name: "-データタイプを選択-" },
        {
          id: "event_totalwinpoint_ranking",
          name: "イベントポイントランキング",
        },
        {
          id: "event_totaljoin_ranking",
          name: "イベントプレイヤー詳細ランキング",
        },
        {
          id: "event_totalcharacter_ranking",
          name: "イベント総合キャラクター使用ランキング",
        },
      ];
    case "tournament":
      return [
        { id: "default", name: "-データタイプを選択-" },
        { id: "tournament_total_ranking", name: "ランキング" },
        { id: "tournament_player_ranking", name: "プレイヤー詳細ランキング" },
        { id: "tournament_character_ranking", name: "キャラクターランキング" },
        { id: "tournament_match_list", name: "試合データ一覧" },
      ];
    case "player":
      return [
        { id: "default", name: "-データタイプを選択-" },
        { id: "player_article", name: "プレイヤー記事作成" }
      ];
  }
};

const ImageContain = styled.img`
  object-fit: contain;
  height: 100%;
`;

export const getAnalyticsColumnData = (type: string) => {
  switch (type) {
    case "tournament_total_ranking":
      return [
        { field: "order_no", headerName: "順位", width: 50 },
        {
          field: "name",
          headerName: "名称",
          width: 250,
          renderCell: (params) => {
            return <div className="rowitem">{params.row.player_info.name}</div>;
          },
        },
        { field: "point", headerName: "ポイント", width: 250 },
      ];
    case "tournament_player_ranking":
      return [
        { field: "order_no", headerName: "順位", width: 50 },
        {
          field: "name",
          headerName: "名称",
          width: 250,
          renderCell: (params) => {
            return <div className="rowitem">{params.row.player_info.name}</div>;
          },
        },
        { field: "point", headerName: "ポイント", width: 250 },
        { field: "game_data_master_name", headerName: "解析名", width: 250 },
      ];
    case "tournament_character_ranking":
      return [
        { field: "order_no", headerName: "順位", width: 50 },
        {
          field: "image",
          headerName: "画像",
          width: 250,
          renderCell: (params) => {
            return (
              <ImageContain
                src={params.row.character_info?.character_imgpath}
              ></ImageContain>
            );
          },
        },
        {
          field: "name",
          headerName: "名称",
          width: 250,
          renderCell: (params) => {
            return (
              <div className="rowitem">
                {params.row.character_info?.character_name}
              </div>
            );
          },
        },
        { field: "point", headerName: "ポイント", width: 250 },
      ];
    case "tournament_match_list":
      return [
        { field: "player1_name", headerName: "プレイヤー1", width: 100 },
        { field: "player2_name", headerName: "プレイヤー2", width: 100 },
        {
          field: "player1_character_img",
          headerName: "キャラクター1",
          width: 300,
          renderCell: (params) => {
            return (
              params.row.player1_character_data?.map((item) => {                
                return <ImageContain src={item.character_imgpath}></ImageContain>
              })
            );
          },
        },
        {
          field: "player2_character_img",
          headerName: "キャラクター2",
          width: 300,
          renderCell: (params) => {
            return (
              params.row.player2_character_data?.map((item) => {                
                return <ImageContain src={item.character_imgpath}></ImageContain>
              })
            );
          },
        },
        { field: "player1_score", headerName: "P1score", width: 100 },
        { field: "player2_score", headerName: "P2score", width: 100 },
      ];
    case "event_totalwinpoint_ranking":
      return [
        { field: "order_no", headerName: "順位", width: 50 },
        {
          field: "name",
          headerName: "名称",
          width: 250,
          renderCell: (params) => {
            return <div className="rowitem">{params.row.player_info.name}</div>;
          },
        },
        { field: "point", headerName: "ポイント", width: 250 },
      ];
    case "event_totaljoin_ranking":
      return [
        { field: "order_no", headerName: "順位", width: 50 },
        {
          field: "name",
          headerName: "名称",
          width: 250,
          renderCell: (params) => {
            return <div className="rowitem">{params.row.player_info.name}</div>;
          },
        },
        { field: "point", headerName: "ポイント", width: 250 },
        { field: "game_data_master_name", headerName: "解析名", width: 250 },
      ];
    case "event_totalcharacter_ranking":
      return [
        { field: "order_no", headerName: "順位", width: 50 },
        {
          field: "image",
          headerName: "画像",
          width: 250,
          renderCell: (params) => {
            return (
              <ImageContain
                src={params.row.character_info.character_imgpath}
              ></ImageContain>
            );
          },
        },
        {
          field: "name",
          headerName: "名称",
          width: 250,
          renderCell: (params) => {
            return (
              <div className="rowitem">
                {params.row.character_info.character_name}
              </div>
            );
          },
        },
        { field: "point", headerName: "ポイント", width: 250 },
      ];
    case "game_character_ranking":
      return [
        { field: "order_no", headerName: "順位", width: 50 },
        {
          field: "name",
          headerName: "名称",
          width: 250,
          renderCell: (params) => {
            return <div className="rowitem">{params.row.player_info.name}</div>;
          },
        },
        { field: "point", headerName: "ポイント", width: 250 },
      ];
    case "game_character_point_ranking":
      return [
        { field: "order_no", headerName: "順位", width: 50 },
        {
          field: "name",
          headerName: "名称",
          width: 250,
          renderCell: (params) => {
            return <div className="rowitem">{params.row.player_info.name}</div>;
          },
        },
        { field: "point", headerName: "ポイント", width: 250 },
        { field: "game_data_master_name", headerName: "解析名", width: 250 },
      ];
    case "game_player_total_ranking":
      return [
        { field: "order_no", headerName: "順位", width: 50 },
        {
          field: "image",
          headerName: "画像",
          width: 250,
          renderCell: (params) => {
            return (
              <ImageContain
                src={params.row.character_info.character_imgpath}
              ></ImageContain>
            );
          },
        },
        {
          field: "name",
          headerName: "名称",
          width: 250,
          renderCell: (params) => {
            return (
              <div className="rowitem">
                {params.row.character_info.character_name}
              </div>
            );
          },
        },
        { field: "point", headerName: "ポイント", width: 250 },
      ];
    case "player_total_ranking":
      return;
    case "player_tournament_ranking":
      return;
  }
  return [
    { field: "order_no", headerName: "順位", width: 50 },
    {
      field: "name",
      headerName: "名称",
      width: 250,
      renderCell: (params) => {
        return <div className="rowitem">{params.row.player_info.name}</div>;
      },
    },
    { field: "point", headerName: "ポイント", width: 250 },
  ];
};

export const getfetchAnalyticsFunction = (dataType: string) => {
  switch (dataType) {
    case "tournament_total_ranking":
      return gettournamentranking;
    case "tournament_player_ranking":
      return gettournamentplayerranking;
    case "tournament_character_ranking":
      return gettournamentcharacterranking;
    case "tournament_match_list":
      return gettournamentmatchlist;
    case "event_totalwinpoint_ranking":
      return geteventranking;
    case "event_totaljoin_ranking":
      return geteventplayerranking;
    case "event_totalcharacter_ranking":
      return geteventcharacterranking;
    case "game_character_ranking":
      return getgameranking;
    case "game_character_point_ranking":
      return getgamecharacterplayranking;
    case "game_player_total_ranking":
      return getgamecharacterplayingranking;
    case "player_total_ranking":
      return getplayertotalanalytics;
    case "player_tournament_ranking":
      return getplayertournamentscoreanalytics;
    case "player_article":
      return getplayerpersonalanalytics;
  }
  return null;
};

export const getFetchAnalyticsPayload = (dataType: string, id) => {
  switch (dataType) {
    case "tournament_total_ranking":
    case "tournament_player_ranking":
    case "tournament_character_ranking":
    case "tournament_match_list":
      return {
        tournament_id: id,
      };
    case "event_totalwinpoint_ranking":
    case "event_totaljoin_ranking":
    case "event_totalcharacter_ranking":
      return {
        event_id: id,
      };
    case "game_character_ranking":
    case "game_character_point_ranking":
    case "game_player_total_ranking":
    case "game_player_tournament_ranking":
      return {
        game_id: id,
      };
    case "player_total_ranking":
    case "player_tournament_ranking":
      return {
        id: id,
      };
    case "player_article":
      return {
        custom_id: id,
      };
  }
  return null;
};
