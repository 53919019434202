import { Breadcrumbs, Button } from "@mui/material/";
import { Box } from "@mui/system";
import { DataGrid, GridColDef, GridRowsProp } from "@mui/x-data-grid";
import React from "react";
import { useCookies } from "react-cookie";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useSelector } from "store/configureStore";

import ActionCell from "../../component/datagrid/GridAction";
import ImageCell from "../../component/datagrid/GridImage";
import { setState as setCommonState } from "../../store/Common/information";
import store from "../../store/configureStore";
import {
  deleteTournamentData,
  fetchTournamentList,
} from "../../store/Site/operations";

interface Props {}

const Tournament: React.FC<Props> = () => {
  const dispatch = useDispatch();
  const [cookies, setCookie, removeCookie] = useCookies(["bs-token"]);
  const data: boolean = useSelector((state) => state.site.tournamentData);

  const navigate = useNavigate();

  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", width: 50 },
    {
      field: "imgpath",
      headerName: "イベント画像",
      width: 250,
      renderCell: (params) => <ImageCell value={params.value} />,
    },
    { field: "name", headerName: "トーナメント名", width: 250 },
    {
      field: "event",
      headerName: "イベント名",
      width: 250,
      renderCell: (params) => {
        return <div className="rowitem">{params.row.event.eventname}</div>;
      },
    },
    {
      field: "event.game.name",
      headerName: "ゲーム",
      width: 200,
      renderCell: (params) => {
        return <div className="rowitem">{params.row.event.game.name}</div>;
      },
    },
    {
      field: "actions",
      headerName: "操作",
      width: 200,
      renderCell: (params) => (
        <ActionCell
          onPlayerRegist={() => handlePlayerRegist(params.row.id)}
          onRegist={() => handleRegist(params.row.id)}
          onEdit={() => handleEdit(params.row.id)}
          onClick={() => handleDelete(params.row.id)}
          onAnalytics={() => handleAnalytics(params.row.id)}
        />
      ),
    },
  ];

  const handlePlayerRegist = (id: number) => {
    navigate(`/site/regist/player/${id}`);
  };
  const handleRegist = (id: number) => {
    navigate(`/site/regist/match/${id}`);
  };
  const handleEdit = (id: number) => {
    navigate(`/site/tournament/edit/${id}`);
  };
  const handleAnalytics = (id: number) => {
    navigate(`/site/common/analytics/tournament/${id}`);
  };
  const handleDelete = (id: number) => {
    store.dispatch(
      setCommonState({
        confirmDialogOpen: true,
        confirmDialogMessage: "トーナメントデータを削除してもよろしいですか？",
        confirmFunction: async () => {
          const result = await dispatch<
            ReturnType<typeof deleteTournamentData>
          >(
            deleteTournamentData({
              payload: {
                id: id,
              },
              access_token: cookies["bs-access-token"],
            })
          );
          if (result.error == undefined) {
            store.dispatch(
              setCommonState({
                alertDialogOpen: true,
                alertDialogType: "success",
                alertDialogMessage: "データを削除しました",
                confirmDialogOpen: false,
              })
            );
            fetchData();
          }
        },
      })
    );
  };
  const handleCreate = () => {
    navigate(`/site/tournament/edit/`);
  };

  const fetchData = async () => {
    const result = await dispatch<ReturnType<typeof fetchTournamentList>>(
      fetchTournamentList(cookies["bs-access-token"])
    );
  };
  React.useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      <Breadcrumbs aria-label="breadcrumb">
        <Link>トーナメント一覧</Link>
      </Breadcrumbs>
      <h2>
        管理トーナメント一覧
        <Button onClick={handleCreate} sx={{ ml: 2 }} variant="contained">
          新規登録
        </Button>
      </h2>
      <Box sx={{ backgroundColor: "white" }}>
        <DataGrid rowHeight={100} rows={data} columns={columns} />
      </Box>
    </div>
  );
};

export default Tournament;
