import { createAsyncThunk } from "@reduxjs/toolkit";

import { execute, Request } from "../../util/request";

const API_ENDPOINT = "/api/";
interface Args {
  payload: JSON;
  access_token: string;
}

export const fetchTournamentList = createAsyncThunk(
  "site/geteventlist",
  async (access_token: string) => {
    const request: Request = {
      endpoint: API_ENDPOINT + "geteventlist",
      method: "GET",
    };
    return await execute(
      request,
      "geteventlist failed",
      undefined,
      access_token
    );
  }
);
export const fetchAllEventList = createAsyncThunk(
  "site/getalleventdata",
  async (args: Args) => {
    const request: Request = {
      endpoint: API_ENDPOINT + "getalleventdata",
      method: "POST",
    };
    return await execute(
      request,
      "getalleventdata failed",
      args.payload,
      args.access_token
    );
  }
);
export const fetchEventData = createAsyncThunk(
  "site/geteventorigindata",
  async (args: Args) => {
    const request: Request = {
      endpoint: API_ENDPOINT + "geteventorigindata",
      method: "POST",
    };
    return await execute(
      request,
      "geteventorigindata failed",
      args.payload,
      args.access_token
    );
  }
);
export const editEventData = createAsyncThunk(
  "site/editeventdata",
  async (args: Args) => {
    const request: Request = {
      endpoint: API_ENDPOINT + "editeventdata",
      method: "POST",
    };
    return await execute(
      request,
      "editeventdata failed",
      args.payload,
      args.access_token
    );
  }
);
export const deleteEventData = createAsyncThunk(
  "site/deleteeventdata",
  async (args: Args) => {
    const request: Request = {
      endpoint: API_ENDPOINT + "deleteeventdata",
      method: "POST",
    };
    return await execute(
      request,
      "deleteeventdata failed",
      args.payload,
      args.access_token
    );
  }
);
export const fetchTournamentData = createAsyncThunk(
  "site/gettournamentdata",
  async (args: Args) => {
    const request: Request = {
      endpoint: API_ENDPOINT + "gettournamentdata",
      method: "POST",
    };
    return await execute(
      request,
      "gettournamentdata failed",
      args.payload,
      args.access_token
    );
  }
);
export const editTournamentData = createAsyncThunk(
  "site/edittournamentdata",
  async (args: Args) => {
    const request: Request = {
      endpoint: API_ENDPOINT + "edittournamentdata",
      method: "POST",
    };
    return await execute(
      request,
      "edittournamentdata failed",
      args.payload,
      args.access_token
    );
  }
);
export const deleteTournamentData = createAsyncThunk(
  "site/deletetournamentdata",
  async (args: Args) => {
    const request: Request = {
      endpoint: API_ENDPOINT + "deletetournamentdata",
      method: "POST",
    };
    return await execute(
      request,
      "deletetournamentdata failed",
      args.payload,
      args.access_token
    );
  }
);
export const fetchPlayerInfoDatas = createAsyncThunk(
  "site/getplayerinfodatas",
  async (args: Args) => {
    const request: Request = {
      endpoint: API_ENDPOINT + "getplayerinfodatas",
      method: "POST",
    };
    return await execute(
      request,
      "getplayerinfodatas failed",
      args.payload,
      args.access_token
    );
  }
);
export const uploadPlayerCSV = createAsyncThunk(
  "site/uploadplayercsv",
  async (args: Args) => {
    const request: Request = {
      endpoint: API_ENDPOINT + "uploadplayercsv",
      method: "POST",
    };
    return await execute(
      request,
      "uploadplayercsv failed",
      args.payload,
      args.access_token
    );
  }
);
export const registPlayerInfo = createAsyncThunk(
  "site/registplayerinfo",
  async (args: Args) => {
    const request: Request = {
      endpoint: API_ENDPOINT + "registplayerinfo",
      method: "POST",
    };
    return await execute(
      request,
      "registplayerinfo failed",
      args.payload,
      args.access_token
    );
  }
);
export const updateplayerrank = createAsyncThunk(
  "site/updateplayerrank",
  async (args: Args) => {
    const request: Request = {
      endpoint: API_ENDPOINT + "updateplayerrank",
      method: "POST",
    };
    return await execute(
      request,
      "updateplayerrank failed",
      args.payload,
      args.access_token
    );
  }
);
export const deletePlayerInfo = createAsyncThunk(
  "site/deleteplayerinfo",
  async (args: Args) => {
    const request: Request = {
      endpoint: API_ENDPOINT + "deleteplayerinfo",
      method: "POST",
    };
    return await execute(
      request,
      "deleteplayerinfo failed",
      args.payload,
      args.access_token
    );
  }
);
export const getmatchdatas = createAsyncThunk(
  "site/getmatchdatas",
  async (args: Args) => {
    const request: Request = {
      endpoint: API_ENDPOINT + "getmatchdatas",
      method: "POST",
    };
    return await execute(
      request,
      "getmatchdatas failed",
      args.payload,
      args.access_token
    );
  }
);
export const getmatchdata = createAsyncThunk(
  "site/getmatchdata",
  async (args: Args) => {
    const request: Request = {
      endpoint: API_ENDPOINT + "getmatchdata",
      method: "POST",
    };
    return await execute(
      request,
      "getmatchdata failed",
      args.payload,
      args.access_token
    );
  }
);
export const registmatchdata = createAsyncThunk(
  "site/registmatchdata",
  async (args: Args) => {
    const request: Request = {
      endpoint: API_ENDPOINT + "registmatchdata",
      method: "POST",
    };
    return await execute(
      request,
      "registmatchdata failed",
      args.payload,
      args.access_token
    );
  }
);
export const deletematchdata = createAsyncThunk(
  "site/deletematchdata",
  async (args: Args) => {
    const request: Request = {
      endpoint: API_ENDPOINT + "deletematchdata",
      method: "POST",
    };
    return await execute(
      request,
      "deletematchdata failed",
      args.payload,
      args.access_token
    );
  }
);
export const updatematchdata = createAsyncThunk(
  "site/updatematchdata",
  async (args: Args) => {
    const request: Request = {
      endpoint: API_ENDPOINT + "updatematchdata",
      method: "POST",
    };
    return await execute(
      request,
      "updatematchdata failed",
      args.payload,
      args.access_token
    );
  }
);
export const getPlayerList = createAsyncThunk(
  "site/getplayerlist",
  async (access_token: string) => {
    const request: Request = {
      endpoint: API_ENDPOINT + "getplayerlist",
      method: "POST",
    };
    return await execute(
      request,
      "getplayerlist failed",
      undefined,
      access_token
    );
  }
);
export const getPlayerData = createAsyncThunk(
  "site/getplayerdata",
  async (args: Args) => {
    const request: Request = {
      endpoint: API_ENDPOINT + "getplayerdata",
      method: "POST",
    };
    return await execute(
      request,
      "getplayerdata failed",
      args.payload,
      args.access_token
    );
  }
);
export const editPlayerData = createAsyncThunk(
  "site/editplayerdata",
  async (args: Args) => {
    const request: Request = {
      endpoint: API_ENDPOINT + "editplayerdata",
      method: "POST",
    };
    return await execute(
      request,
      "editplayerdata failed",
      args.payload,
      args.access_token
    );
  }
);