import isEmpty from "lodash/isEmpty";

import { setState } from "../store/Common/information";
import store from "../store/configureStore";

export type Request = {
  endpoint: string;
  method: string;
};

export const execute = async (
  request: Request,
  errorMessage: string,
  payload?: JSON,
  access_token?: string
): Promise<any> => {
  store.dispatch(setState({ loading: true }));
  const response = await useRequest(request, payload, access_token);
  store.dispatch(setState({ loading: false }));

  if (!response.ok) {
    const data = await response.json();
    store.dispatch(setState({ errorMessage: data.message }));
    throw new Error(errorMessage);
  }

  return response.json();
};

export const useRequest = (
  request: Request,
  payload?: JSON,
  access_token?: string
): Promise<Response> => {
  const token: HTMLElement | null = document.querySelector(
    'meta[name="api-token"]'
  );
  let apiToken: string = <string>token?.getAttribute("content");
  if (access_token != undefined) apiToken = access_token;
  if (payload != undefined && payload["file"] != undefined) {
    const headers = new Headers({
      "X-Requested-With": "XMLHttpRequest",
      Authorization: "Bearer " + apiToken,
    });

    const formData = new FormData();
    formData.append("file", payload["file"]);
    formData.append("json", JSON.stringify(payload));

    return fetch(request.endpoint, {
      headers,
      method: request.method,
      body: formData,
      cache: "no-cache",
      credentials: "same-origin",
    });
  } else {
    const headers = new Headers({
      "Content-Type": "application/json",
      Accept: "application/json",
      "X-Requested-With": "XMLHttpRequest",
      Authorization: "Bearer " + apiToken,
    });

    return fetch(request.endpoint, {
      headers,
      method: request.method,
      body: isEmpty(payload) ? null : JSON.stringify(payload),
      cache: "no-cache",
      credentials: "same-origin",
    });
  }
};
