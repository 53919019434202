import { Box, Breadcrumbs, Button, TextField } from "@mui/material/";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { DataGrid, GridColDef, GridRowsProp } from "@mui/x-data-grid";
import React, { useEffect, useRef, useState } from "react";
import { useAsyncCallback } from "react-async-hook";
import { useCookies } from "react-cookie";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "store/configureStore";

import noimage from "../../../assets/noimage.png";
import CircularIntegration from "../../component/parts/CircularIntegration";
import {
  ArticleData,
  TournamentRankingData,
} from "../../store/Analytics/definition";
import { resetRankingData } from "../../store/Analytics/information";
import {
  editarticle,
  fetchAnalyticsTournament,
  getarticle,
  gettournamentranking,
  makebattlescorearticle,
} from "../../store/Analytics/operations";
import { setState as setCommonState } from "../../store/Common/information";
import store from "../../store/configureStore";
import {
  getAnalyticsColumnData,
  getfetchAnalyticsFunction,
  getFetchAnalyticsPayload,
  selectAnalyticsTypeData,
} from "../../util/analyticsdata";
import { CustomTabPanel } from "./CustomTabPanel";
import { EventData, TournamentOneData } from "../../store/Site/definition";
import { fetchEventData, fetchTournamentData } from "../../store/Site/operations";
import { fetchGameData, getgamedatamaster, getgamedatamasterone } from "../../store/Admin/operations";

interface Props { }

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Analytics: React.FC<Props> = () => {
  const dispatch = useDispatch();
  const [cookies, setCookie, removeCookie] = useCookies(["bs-token"]);
  const { type, id } = useParams();
  const data = useSelector((state) => state.analytics.rankingData);
  const player_analytics_data = useSelector((state) => state.analytics.playerAnalyticsData)
  const article: ArticleData = useSelector(
    (state) => state.analytics.articleData
  );
  const loading: boolean = useSelector((state) => state.information.loading);

  const tournament_data: TournamentOneData = useSelector((state) => state.site.tournamentOneData);
  const event_data:EventData = useSelector((state) => state.site.eventOneData);
  const game_data: boolean = useSelector((state) => state.admin.gameOneData);

  const [columns, setColumns] = useState<GridColDef[]>([]);

  const getSelectDataType = () => {
    return selectAnalyticsTypeData(type);
  };  
  const getBreadCrumsList = () => {
    switch (type) {
      case "game":
        return <Link to="/admin/game">ゲームデータ一覧</Link>;
      case "event":
        return <Link to="/site/event">イベントデータ一覧</Link>;
      case "tournament":
        return <Link to="/site/tournament">トーナメントデータ一覧</Link>;
      case "player":
        return <div>プレイヤー解析</div>;
    }
  };
  const [aiTitleTxt, setAiTitleTxt] = useState<string>("");
  const [aiCommentTxt, setAiCommentTxt] = useState<string>("");
  const [excerptTxt, setExcerptTxt] = useState<string>("");
  const [operatorCommentTxt, setOperatorCommentTxt] = useState<string>("");
  const [tagsTxt, setTagsTxt] = useState<string>("");
  const [categoryTxt, setCategoryTxt] = useState<string>("");
  const [uploadFileData, setUploadFileData] = useState(null);
  const [previewData, setPreviewData] = useState(null);
  const inputRef = useRef(null);
  const [success, setSuccess] = useState(false);

  const uploadFile = async (file) => {
    if (!file) return;
    setUploadFileData(file);
    setPreviewData(window.URL.createObjectURL(file));
    setSuccess(true);
  };
  const onFileInputChange = async (event) => {
    const file = event.target.files[0];
    await uploadFile(file);
  };
  const clickFileUploadButton = () => {
    setSuccess(false);
    inputRef.current.click();
  };
  const asyncEvent = useAsyncCallback(onFileInputChange);

  const [dataString,setDataString] = useState<string>("");
  const [dataType, setDataType] = useState<string>("default");
  const handleChange = (event: SelectChangeEvent) => {
    setDataType(event.target.value as string);    
    const selectData = getSelectDataType();
    //selectDataのidと一致するnameを取得
    const selectDataName = selectData?.filter((item) => {
      return item.id == event.target.value;
    });
    setDataString(selectDataName[0].name);
  };
  const [articleId, setArticleId] = useState<number>(0);

  const initDefaultCategory = () => {
    switch (type) {
      case "game":
        if(categoryTxt==""){
          setCategoryTxt("ゲーム");
        }        
        break;
      case "event":
        if(categoryTxt==""){
          setCategoryTxt("イベント,ランキング");
        }
        break;
      case "tournament":
        if(categoryTxt==""){
          setCategoryTxt("トーナメント,ランキング");
        }
        break;
      case "player":
        if(categoryTxt==""){
          setCategoryTxt("プレイヤー");
        }
        break;
    }
    if(tagsTxt==""){
      setTagsTxt("Sole Survivor");
    }
  }
  const resetInputTxt = () => {
    setArticleId("");
    setAiTitleTxt("");
    setAiCommentTxt("");
    setOperatorCommentTxt("");
    setExcerptTxt("");
    setPreviewData("");
    setTagsTxt("");
    setCategoryTxt("");
    setPreviewData(null);
    setUploadFileData(null);
    dispatch(resetRankingData());

  };
  const fetchArticleData = async () => {
    resetInputTxt();
    setColumns(getAnalyticsColumnData(dataType));
    //1. article情報を取得
    await fetchArticleInfo();
    //2. 選択タイプによってランキングデータを取得
    await fetchRankingData();
    //3. 現在のタイプによってトーナメント、イベント情報を取得
    await fetchInformationData();
    initDefaultCategory();
  };
  const fetchArticleInfo = async () => {
    const send_payload_data = {
      ranking_type: dataType,
      article_type: type,
    };
    switch (type) {
      case "game":
        send_payload_data["game_id"] = id;
        break;
      case "event":
        send_payload_data["event_id"] = id;
        break;
      case "tournament":
        send_payload_data["tournament_id"] = id;
        break;
      case "player":
        send_payload_data["custom_id"] = id;
        break;
    }

    const result = await dispatch<ReturnType<typeof getarticle>>(
      getarticle({
        payload: send_payload_data,
        access_token: cookies["bs-access-token"],
      })
    );
    if (result.error == undefined) {
      if (result.payload != undefined && result.payload.id != undefined) {
        setArticleId(result.payload.id);
        setAiTitleTxt(result.payload.article_title);
        const comment_data = JSON.parse(result.payload.article_comment);
        setAiCommentTxt(comment_data.ai_comment);
        setOperatorCommentTxt(comment_data.operator_comment);
        setExcerptTxt(comment_data.excerpt);
        setPreviewData(result.payload.eyecatch);
        const attribute = JSON.parse(result.payload.article_attribute);
        setTagsTxt(attribute?.tags);
        setCategoryTxt(attribute?.category);
      }
    }
  };
  const fetchRankingData = async () => {
    const fetchFunction = getfetchAnalyticsFunction(dataType);
    const fetchPayload = getFetchAnalyticsPayload(dataType, id);
    if (fetchFunction != undefined) {
      const result = await dispatch<ReturnType<typeof fetchFunction>>(
        fetchFunction({
          payload: fetchPayload,
          access_token: cookies["bs-access-token"],
        })
      );
    }
  };


  const fetchInformationData = async () => {    
    switch (type) {
      case "game":
        await dispatch<ReturnType<typeof fetchGameData>>(
          fetchGameData({
                payload: {
                  id: id,
                },
                access_token: cookies["bs-access-token"],
              })
            );
        break;
      case "event":
        await dispatch<ReturnType<typeof fetchEventData>>(
          fetchEventData({
            payload: {
              id: id,
            },
            access_token: cookies["bs-access-token"],
          })
        );
        break;
      case "tournament":
         await dispatch<ReturnType<typeof fetchTournamentData>>(
          fetchTournamentData({
            payload: {
              id: id,
            },
            access_token: cookies["bs-access-token"],
          })
        );
        break;
    }
  }

  const [searchOk, setSearchOk] = useState<boolean>(false);
  const handleDataSearch = () => {
    if (dataType !== "default") {
      fetchArticleData();
      setSearchOk(true);
    } else {
      setSearchOk(false);
    }
  };
  const handleAnalyticsTournament = async () => {
    const result = await dispatch<ReturnType<typeof fetchAnalyticsTournament>>(
      fetchAnalyticsTournament({
        payload: {
          tournament_id: id,
        },
        access_token: cookies["bs-access-token"],
      })
    );
    if (result.error == undefined) {
      store.dispatch(
        setCommonState({
          alertDialogOpen: true,
          alertDialogType: "success",
          alertDialogMessage: "データ解析に成功しました",
          confirmDialogOpen: false,
        })
      );
    }
  };
  const handleAiTitle = () => { };
  const getArticleSendPayloadData = () => {
    const send_payload_data = {
      id: articleId,
      ranking_type: dataType,
      article_type: type,
      article_title: aiTitleTxt,
      article_ai_comment: aiCommentTxt,
      article_operator_comment: operatorCommentTxt,
      article_excerpt: excerptTxt,
      article_tags: tagsTxt,
      article_category: categoryTxt,
      file: uploadFileData,
    };
    switch (type) {
      case "game":
        send_payload_data["game_id"] = id;
        break;
      case "event":
        send_payload_data["event_id"] = id;
        break;
      case "tournament":
        send_payload_data["tournament_id"] = id;
        break;
      case "player":
        send_payload_data["custom_id"] = id;
        send_payload_data["analytics_data"] = player_analytics_data;
        break;
    }
    return send_payload_data;
  };
  const handleSaveArticleData = async () => {
    const send_payload_data = getArticleSendPayloadData();
    const result = await dispatch<ReturnType<typeof editarticle>>(
      editarticle({
        payload: send_payload_data,
        access_token: cookies["bs-access-token"],
      })
    );
    if (result.error == undefined) {
      const article_id = result.payload.id;
      setArticleId(article_id);

      store.dispatch(
        setCommonState({
          alertDialogOpen: true,
          alertDialogType: "success",
          alertDialogMessage: "データ投稿に成功しました",
          confirmDialogOpen: false,
        })
      );
      await fetchArticleInfo();
    }
  };
  const handlePostArticle = async () => {
    if (!loading) {
      const send_payload_data = getArticleSendPayloadData();
      const result_edit = await dispatch<ReturnType<typeof editarticle>>(
        editarticle({
          payload: send_payload_data,
          access_token: cookies["bs-access-token"],
        })
      );

      const result = await dispatch<ReturnType<typeof makebattlescorearticle>>(
        makebattlescorearticle({
          payload: {
            article_id: articleId,
          },
          access_token: cookies["bs-access-token"],
        })
      );
      if (result.error == undefined) {
        store.dispatch(
          setCommonState({
            alertDialogOpen: true,
            alertDialogType: "success",
            alertDialogMessage: "データ投稿に成功しました",
            confirmDialogOpen: false,
          })
        );
      }
    }
  };

  const [tabvalue, setTabValue] = React.useState(0);
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    setSearchOk(false);
    setDataType("default");
    resetInputTxt();
  }, [type]);

  useEffect(() => {
  }, [player_analytics_data])

  return (
    <div>
      <Breadcrumbs aria-label="breadcrumb">
        {getBreadCrumsList()}
        <Link>解析</Link>
      </Breadcrumbs>
      <Box sx={{ mt: 1, backgroundColor: "white" }}>
        トーナメント総合解析
        <br />
        <Button
          sx={{ ml: 1 }}
          onClick={handleAnalyticsTournament}
          variant="contained"
          color="primary"
        >
          総合解析
        </Button>
      </Box>
      <Box sx={{ mt: 1, backgroundColor: "white" }}>
        <InputLabel id="data_type_label">データタイプ</InputLabel>
        <Select
          labelId="data_type_label"
          id="data_type"
          value={dataType}
          label="dataType"
          onChange={handleChange}
          sx={{ minWidth: 500 }}
        >
          {getSelectDataType()?.map((item) => {
            return (
              <MenuItem key={item.id} value={item.id}>
                {item.name}
              </MenuItem>
            );
          })}
        </Select>
        <Button
          sx={{ ml: 1 }}
          onClick={handleDataSearch}
          variant="contained"
          color="primary"
        >
          選択
        </Button>
      </Box>
      {searchOk ? (
        <Box sx={{ mt: 1, backgroundColor: "white" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={tabvalue}
              onChange={handleTabChange}
              aria-label="game_tabs"
            >
              <Tab label="データ表示" {...a11yProps(0)} />
              <Tab label="データ登録" {...a11yProps(1)} />
            </Tabs>
          </Box>

          <CustomTabPanel value={tabvalue} index={0}>
            <Box sx={{ mt: 1 }}>
              {type=="player" && player_analytics_data.length !== 0 ? (
                <div>
                  <h3>プレイヤー情報({player_analytics_data?.player_name}/{player_analytics_data?.custom_id})</h3>
                  {player_analytics_data?.event_data?.map((item) => (
                    <div>
                      <div>{item.name}({item.event_id})</div>
                      <div>
                        <img src={item.event_order_graph} />
                      </div>
                      <div>トーナメントデータ</div>
                      <div>
                        {item.tournament_info.map((tournament) => (
                          <table border="1">
                            <tr>
                              <td>グラフ</td>
                              <td style={{ width: 240 }}>名前</td>
                              <td style={{ width: 80 }}>順位</td>
                              <td>イベント日</td>
                            </tr>
                            <tr>
                              <td><img src={tournament.graph} width="200px" /></td>
                              <td>{tournament.name}</td>
                              <td>{tournament.order_no} 位</td>
                              <td>{tournament.eventdate}</td>
                            </tr>
                          </table>
                        ))}

                      </div>
                    </div>
                  ))}
                </div>
              ) : ""}
              {data != undefined && data.length > 0 ? (
                <DataGrid rowHeight={100} rows={data} columns={columns} />
              ) : ""}
            </Box>
          </CustomTabPanel>

          <CustomTabPanel value={tabvalue} index={1}>
            <Box>
              <div>選択中のデータタイプ：{dataString}</div>
              {type=="tournament" && tournament_data!=undefined ? (
                <>
                  トーナメント名：{tournament_data.tournament.name}<br/>                  
                </>
                ):""}
              {type=="event" && event_data!=undefined ? (
                <>
                  イベント名：{event_data.eventname}<br/>                  
                </>
                ):""}                   
              {type=="game" && game_data!=undefined ? (
                <>
                  ゲーム名：{game_data.name}<br/>                  
                </>
                ):""}  
            </Box>
            <Box sx={{ mt: 2, display: "flex", flexDirection: "row" }}>
              <TextField
                id="titleTxt"
                sx={{ width: 800 }}
                value={aiTitleTxt}
                onChange={(event) => setAiTitleTxt(event.target.value)}
                label="タイトル"
                variant="outlined"
              />
              <Button
                sx={{ mt: 1, ml: 1, height: 36 }}
                onClick={handleAiTitle}
                variant="contained"
                color="primary"
              >
                候補表示
              </Button>
            </Box>
            <Box sx={{ mt: 1 }}>
              <TextField
                id="excerpt_txt"
                sx={{ width: 800 }}
                value={excerptTxt}
                multiline
                rows={4}
                onChange={(event) => setExcerptTxt(event.target.value)}
                label="概要"
                variant="outlined"
              />
            </Box>
            <Box sx={{ mt: 1 }}>
              <TextField
                id="ai_comment_txt"
                sx={{ width: 800 }}
                value={aiCommentTxt}
                multiline
                rows={4}
                onChange={(event) => setAiCommentTxt(event.target.value)}
                label="コメント-ai-"
                variant="outlined"
              />
            </Box>
            <Box sx={{ mt: 1 }}>
              <TextField
                id="op_comment_txt"
                sx={{ width: 800 }}
                value={operatorCommentTxt}
                multiline
                rows={4}
                onChange={(event) => setOperatorCommentTxt(event.target.value)}
                label="コメント-operator-"
                variant="outlined"
              />
            </Box>
            <Box sx={{ mt: 2, display: "flex", flexDirection: "row" }}>
              <TextField
                id="tagsTxt"
                sx={{ width: 800 }}
                value={tagsTxt}
                onChange={(event) => setTagsTxt(event.target.value)}
                label="タグ"
                variant="outlined"
              />
            </Box>
            <Box sx={{ mt: 2, display: "flex", flexDirection: "row" }}>
              <TextField
                id="categoryTxt"
                sx={{ width: 800 }}
                value={categoryTxt}
                onChange={(event) => setCategoryTxt(event.target.value)}
                label="カテゴリ"
                variant="outlined"
              />              
            </Box>
            <Box sx={{ mt: 2, display: "flex", flexDirection: "row" }}>
            ※タグとカテゴリは , 区切りで複数登録可能です  
            </Box>            
            <Box sx={{ mt: 1, display: "flex", flexDirection: "row" }}>
              <img
                height={120}
                src={previewData == null ? noimage : previewData}
              />
              <CircularIntegration
                onClick={clickFileUploadButton}
                asyncEvent={asyncEvent}
                success={success}
                component="label"
                text={asyncEvent.loading ? "..." : "アイキャッチ画像を選択"}
              />
              <input
                hidden
                ref={inputRef}
                type="file"
                onChange={asyncEvent.execute}
              />
            </Box>
            <Box sx={{ mt: 2 }}>
              <Button
                onClick={handleSaveArticleData}
                sx={{ ml: 2 }}
                variant="contained"
              >
                投稿データ登録 ( ID:{articleId} )
              </Button>
            </Box>
            <Box sx={{ mt: 1 }}>
              <Button
                onClick={handlePostArticle}
                disabled={articleId == 0 ? true : false}
                sx={{ ml: 2 }}
                variant="contained"
              >
                データ投稿
              </Button>
            </Box>
          </CustomTabPanel>
        </Box>
      ) : (
        <div></div>
      )}
    </div>
  );
};

export default Analytics;
