import { createAsyncThunk } from "@reduxjs/toolkit";

import { execute, Request } from "../../util/request";

const API_ENDPOINT = "/api/";
interface Args {
  payload: JSON;
  access_token: string;
}

export const fetchLogin = createAsyncThunk("user/login", async (arg: Args) => {
  const request: Request = {
    endpoint: API_ENDPOINT + "login",
    method: "POST",
  };
  return await execute(request, "login failed", arg.payload);
});
export const fetchRegist = createAsyncThunk(
  "user/register",
  async (arg: Args) => {
    const request: Request = {
      endpoint: API_ENDPOINT + "register",
      method: "POST",
    };
    return await execute(request, "regist failed", arg.payload);
  }
);
export const fetchUser = createAsyncThunk(
  "user/getUser",
  async (access_token: string) => {
    const request: Request = { endpoint: API_ENDPOINT + "user", method: "GET" };
    return await execute(
      request,
      "authentication check failed",
      undefined,
      access_token
    );
  }
);
export const fetchOrganizer = createAsyncThunk(
  "user/getorganizerList",
  async (arg: Args) => {
    const request: Request = {
      endpoint: API_ENDPOINT + "getorganizerList",
      method: "POST",
    };
    return await execute(
      request,
      "authentication check failed",
      arg.payload,
      arg.access_token
    );
  }
);
