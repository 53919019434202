import DeleteIcon from "@mui/icons-material/Delete";
import Tooltip from "@mui/material/Tooltip";
import React from "react";

const UserActionCell = ({ onClick }) => (
  <div>
    <Tooltip title="削除">
      <DeleteIcon sx={{ cursor: "pointer" }} onClick={onClick} />
    </Tooltip>
  </div>
);

export default UserActionCell;
