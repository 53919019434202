import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useDispatch } from "react-redux";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { useSelector } from "store/configureStore";

import Game from "./page/admin/Game";
import GameEdit from "./page/admin/GameEdit";
import Analytics from "./page/common/Analytics";
import Dashboard from "./page/dashboard/Dashboard";
import LoginContainer from "./page/layouts/auth/Login";
import DashboardLayout from "./page/layouts/dashboard/Dashboard";
import CreateUser from "./page/login/CreateUser";
import Login from "./page/login/Login";
import MatchEdit from "./page/regist/MatchEdit";
import MatchRegist from "./page/regist/MatchRegist";
import PlayerRegist from "./page/regist/PlayerRegist";
import Event from "./page/site/Event";
import EventEdit from "./page/site/EventEdit";
import Tournament from "./page/tournament/Tournament";
import TournamentEdit from "./page/tournament/TournamentEdit";
import UserList from "./page/user/UserList";
import { fetchUser } from "./store/Login/operations";
import AiSystem from "./page/admin/AiSystem";
import PLayerList from "./page/player/PlayerList";
import PlayerEdit from "./page/player/PlayerEdit";

interface Props {}

const App: React.FC<Props> = () => {
  const dispatch = useDispatch();
  const loading: boolean = useSelector((state) => state.information.loading);

  const [cookies, setCookie, removeCookie] = useCookies(["bs-token"]);
  const isLoggedIn: boolean = useSelector((state) => state.login.isLoggedIn);
  const [login, setLogin] = useState(null);

  const checkLogin = async () => {
    if (!loading) {
      const result = await dispatch<ReturnType<typeof fetchUser>>(
        fetchUser(cookies["bs-access-token"])
      );
      if (result.error == undefined) {
        setLogin(true);
      } else {
        setLogin(false);
      }
    }
  };

  useEffect(() => {
    checkLogin();
  }, [isLoggedIn]);

  const router = createBrowserRouter([
    {
      path: "/",
      element: login ? (
        <Navigate replace to="/site/tournament" />
      ) : login == false ? (
        <Navigate replace to="/login" />
      ) : (
        ""
      ),
    },
    {
      path: "/login",
      element: login ? (
        <Navigate replace to="/site/tournament" />
      ) : (
        <LoginContainer>
          <Login />
        </LoginContainer>
      ),
    },
    {
      path: "/create",
      element: (
        <LoginContainer>
          <CreateUser />
        </LoginContainer>
      ),
    },
    {
      path: "/dashboard",
      element: login ? (
        <DashboardLayout>
          <Dashboard />
        </DashboardLayout>
      ) : login == false ? (
        <Navigate replace to="/login" />
      ) : (
        ""
      ),
    },
    {
      path: "/site/tournament",
      element: login ? (
        <DashboardLayout>
          <Tournament />
        </DashboardLayout>
      ) : login == false ? (
        <Navigate replace to="/login" />
      ) : (
        ""
      ),
    },
    {
      path: "/site/tournament/edit/",
      element: login ? (
        <DashboardLayout>
          <TournamentEdit />
        </DashboardLayout>
      ) : login == false ? (
        <Navigate replace to="/login" />
      ) : (
        ""
      ),
    },
    {
      path: "/site/tournament/edit/:id",
      element: login ? (
        <DashboardLayout>
          <TournamentEdit />
        </DashboardLayout>
      ) : login == false ? (
        <Navigate replace to="/login" />
      ) : (
        ""
      ),
    },
    {
      path: "/site/regist/player/:tid",
      element: login ? (
        <DashboardLayout>
          <PlayerRegist />
        </DashboardLayout>
      ) : login == false ? (
        <Navigate replace to="/login" />
      ) : (
        ""
      ),
    },
    {
      path: "/site/regist/match/:tid",
      element: login ? (
        <DashboardLayout>
          <MatchRegist />
        </DashboardLayout>
      ) : login == false ? (
        <Navigate replace to="/login" />
      ) : (
        ""
      ),
    },
    {
      path: "/site/regist/match/:tid/edit/:id",
      element: login ? (
        <DashboardLayout>
          <MatchEdit />
        </DashboardLayout>
      ) : login == false ? (
        <Navigate replace to="/login" />
      ) : (
        ""
      ),
    },
    {
      path: "/site/event",
      element: login ? (
        <DashboardLayout>
          <Event />
        </DashboardLayout>
      ) : login == false ? (
        <Navigate replace to="/login" />
      ) : (
        ""
      ),
    },
    {
      path: "/site/event/edit/",
      element: login ? (
        <DashboardLayout>
          <EventEdit />
        </DashboardLayout>
      ) : login == false ? (
        <Navigate replace to="/login" />
      ) : (
        ""
      ),
    },
    {
      path: "/site/event/edit/:id",
      element: login ? (
        <DashboardLayout>
          <EventEdit />
        </DashboardLayout>
      ) : login == false ? (
        <Navigate replace to="/login" />
      ) : (
        ""
      ),
    },
    {
      path: "/site/common/analytics/:type/:id",
      element: login ? (
        <DashboardLayout>
          <Analytics />
        </DashboardLayout>
      ) : login == false ? (
        <Navigate replace to="/login" />
      ) : (
        ""
      ),
    },
    {
      path: "/site/player/",
      element: login ? (
        <DashboardLayout>
          <PLayerList />
        </DashboardLayout>
      ) : login == false ? (
        <Navigate replace to="/login" />
      ) : (
        ""
      ),
    },
    {
      path: "/site/player/edit/:id",
      element: login ? (
        <DashboardLayout>
          <PlayerEdit />
        </DashboardLayout>
      ) : login == false ? (
        <Navigate replace to="/login" />
      ) : (
        ""
      ),
    },
    {
      path: "/admin/game",
      element: login ? (
        <DashboardLayout>
          <Game />
        </DashboardLayout>
      ) : login == false ? (
        <Navigate replace to="/login" />
      ) : (
        ""
      ),
    },
    {
      path: "/admin/game/edit/",
      element: login ? (
        <DashboardLayout>
          <GameEdit />
        </DashboardLayout>
      ) : login == false ? (
        <Navigate replace to="/login" />
      ) : (
        ""
      ),
    },
    {
      path: "/admin/game/edit/:id",
      element: login ? (
        <DashboardLayout>
          <GameEdit />
        </DashboardLayout>
      ) : login == false ? (
        <Navigate replace to="/login" />
      ) : (
        ""
      ),
    },
    {
      path: "/admin/user",
      element: login ? (
        <DashboardLayout>
          <UserList />
        </DashboardLayout>
      ) : login == false ? (
        <Navigate replace to="/login" />
      ) : (
        ""
      ),
    },
    {
      path: "/admin/ai",
      element: login ? (
        <DashboardLayout>
          <AiSystem />
        </DashboardLayout>
      ) : login == false ? (
        <Navigate replace to="/login" />
      ) : (
        ""
      ),
    },
  ]);
  return <RouterProvider router={router} />;
};

export default App;
